import "./index.scss";

import { Menu } from "antd";
import FeatherIcon from "feather-icons-react";
import { Link, useHistory } from "react-router-dom";

import { logo } from "../../Assets";
import logoKraft from "../../Assets/logoKraft.png";
import { NavLinks } from "./constants";
import { useAuthStore } from "../../../Stores/Auth";
import { useProfileStore } from "../../../Stores/Profile";
import { useEffect } from "react";

const SideNav = () => {
  const history = useHistory();
  const [, { logout }] = useAuthStore();
  const [{ profile }, { getProdileData }] = useProfileStore();
  useEffect(() => {
    getProdileData();
  }, [getProdileData]);
  return (
    <div className="SideNav">
      <img style={{width:"70px"}} src={logo} alt="logo" />
      <div className="SideNav-info">
        <h3>{profile?.name}</h3>
        <p>{profile?.address}</p>
      </div>
      <Menu className="SideNav-menu">
        {NavLinks.map(({ key, route, icon, title }) => (
          <Menu.Item key={key}>
            <FeatherIcon icon={icon} className="SideNav-icon" />
            <Link to={route} className="SideNav-text">
              {title}
            </Link>
          </Menu.Item>
        ))}
        <Menu.Item key="signout">
          <FeatherIcon icon="log-out" className="SideNav-icon" />
          <div className="SideNav-text" onClick={() => logout(history)}>
            Sign out
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SideNav;
