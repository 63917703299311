import "./index.scss";
import { Button, Form, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import { useParams } from "react-router-dom";
import routes from "../../../../Common/routes";
import { Link } from "react-router-dom";

const AssignModal = ({ service, form }) => {
  const [
    { isAssigning, loadingAssignModal, assignBayList, assignEmployeeList },
    { handleAssignJobcard, handleGetAssignModalData },
  ] = useJobcardStore();
  const [bay, setbay] = useState(null);
  const [bayErr, setBayErr] = useState(false);
  const { jobId } = useParams();

  useEffect(() => {
    handleGetAssignModalData(() => {
      setbay(service?.bay);
      form.setFieldsValue({ employee: service?.employee });
    });
  }, [handleGetAssignModalData, setbay, service, form]);

  if (loadingAssignModal)
    return <Spin style={{ margin: "auto" }} tip="Loading bays and employees" />;

  return (
    <Form
      form={form}
      requiredMark={false}
      layout="vertical"
      className="bayAssignForm"
      onFinish={(values) =>
        bay === null
          ? setBayErr(true)
          : handleAssignJobcard(
              service?.id,
              { ...values, bay },
              () => {
                form.resetFields();
                setbay(null);
              },
              jobId
            )
      }
    >
      <Form.Item
        label="Select Bay"
        validateStatus={bayErr ? "error" : "success"}
        help={bayErr ? "Please select a bay" : ""}
        validateTrigger={["onChange"]}
      >
        <div className="bayAssignForm-BayList">
          {assignBayList?.length !== 0 ? (
            assignBayList?.map((bayItem) => (
              <p
                key={bayItem?.id}
                className={bay === bayItem?.id && "bayAssignForm-BaySelected"}
                onClick={() => {
                  setbay(bayItem?.id);
                  setBayErr(false);
                }}
              >
                {bayItem?.name}
              </p>
            ))
          ) : (
            <Link to={routes.LIST_BAY}>
              <Button type="primary">Create bays</Button>
            </Link>
          )}
        </div>
      </Form.Item>
      <Form.Item
        name="employee"
        label="Select Employee"
        rules={[{ required: true, message: "Please select a employee" }]}
      >
        <Select>
          {assignEmployeeList.length !== 0 ? (
            assignEmployeeList?.map((empItem) => (
              <Select.Option key={empItem?.id} value={empItem?.id}>
                {empItem?.name}
              </Select.Option>
            ))
          ) : (
            <Link to={routes.LIST_EMPLOYEE}>
              <Button type="primary">Create employee</Button>
            </Link>
          )}
        </Select>
      </Form.Item>
      <br />
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isAssigning}>
          Assign
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AssignModal;
