import "./index.scss";
import { Empty, Menu, Spin, DatePicker } from "antd";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import ListCard from "../../../Common/UI/ListCard";
import ListCollection from "../../../Common/UI/ListCollection";
import Loader from "../../../Common/UI/Loader";
import { useReportStore } from "../../../Stores/Reports";
import { CSVLink } from "react-csv";
import moment from "moment";

const { RangePicker } = DatePicker;

const ViewReport = ({ match }) => {
  const [
    { loading, reportData },
    { handleGetReportData, handleGetTimelineReports },
  ] = useReportStore();
  const history = useHistory();

  const headerName = {
    storeIncome: "Store Income",
    serviceIncome: "Service Income",
    productIncome: "Product Income",
    stockIncome: "Stock Income",
  };

  useEffect(() => {
    handleGetReportData(match?.params?.reportType);
  }, [handleGetReportData, match?.params]);

  const handleTimelineChanges = (values) => {
    if (values.every((val) => val !== null)) {
      handleGetTimelineReports(match?.params?.reportType, values);
    }
  };

  const getCsvData = () => {
    console.log(reportData, "report data");
    let csvArray = [];
    for (let i = 0; i < reportData.length; i++) {
      for (let j = 0; j < reportData[i]?.jobcardservices?.length; j++) {
        
          csvArray.push({
            job_id: reportData[i]?.job_id,
            total_price: reportData[i]?.total_price.toFixed(2),
            floor_manager: reportData[i]?.floor_manager,
            date: moment(reportData?.date_added).format("DD-MM-YYYY"),
            customer_vehicle: reportData[i]?.customer_vehicle_data?.vehicle_no,
            brand: reportData[i]?.customer_vehicle_data?.brand,
            model: reportData[i]?.customer_vehicle_data?.model,
            service_name: reportData[i]?.jobcardservices[j]?.service_name,
            service_price:
              reportData[i]?.jobcardservices[j]?.total_price.toFixed(2),
            service_total: reportData[i]?.jobcardservices[j]?.price.toFixed(2),
            gst:
              reportData[i]?.jobcardservices[j]?.total_price.toFixed(2) -
              reportData[i]?.jobcardservices[j]?.price.toFixed(2),
            employee_name: reportData[i]?.jobcardservices[j]?.employee_name,
          });
        
      }
    }
    console.log(csvArray, "array nowww");
    return csvArray;
  };
  const headers = [
    { label: "JobID", key: "job_id" },
    { label: "Created Date", key: "date" },
    { label: "Floor manager", key: "floor_manager" },
    { label: "Vehicle", key: "customer_vehicle" },
    { label: "Brand", key: "brand" },
    { label: "Model", key: "model" },
    { label: "Employee", key: "employee_name" },
    { label: "Service name", key: "service_name" },
    { label: "Amount", key: "service_price" },
    { label: "Service GST", key: "gst" },
    { label: "Service Total", key: "service_total" },
    { label: "Total Amount", key: "total_price" },
  ];

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  const csvReport = {
    data: getCsvData() && getCsvData(),
    headers: headers,
    filename: `Service Report(${mm + "/" + dd + "/" + yyyy}).csv`,
  };

  return (
    <div>
      <AppBar
        onBack={() => history.push(routes.LIST_REPORTS)}
        dropDownMenu={
          <Menu>
            {loading ? (
              <Spin tip="Loading" />
            ) : (
              <Menu.Item key="export">
                {reportData && <CSVLink {...csvReport}>Export as CSV</CSVLink>}
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>
            {headerName[match?.params?.reportType]
              ? headerName[match?.params?.reportType]
              : "Unknown"}
          </h3>
        </div>
      </AppBar>
      <div className="viewReportContainer">
        <RangePicker
          onChange={handleTimelineChanges}
          className="viewReportContainer-dateInput"
        />
        {loading ? (
          <Loader tip="Loading reports..." />
        ) : reportData?.length === 0 ? (
          <Empty description="No data" />
        ) : (
          <ListCollection>
            {reportData.map((report) => (
              <ListCard
                header={[report?.job_id, ""]}
                body={[report?.customer_vehicle_data?.vehicle_no]}
                footer={[
                  `${report?.jobcardservices?.length} ${
                    report?.jobcardservices?.length > 1 ? "services" : "service"
                  }`,
                  `₹ ${report?.total_price}`,
                ]}
              />
            ))}
          </ListCollection>
        )}
      </div>
    </div>
  );
};

export default ViewReport;
