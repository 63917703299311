import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import routes from "../../../Common/routes";
import { Button, Checkbox, Collapse, Menu } from "antd";
import AppBar from "../../../Common/UI/AppBar";
import "./index.scss";

const { Panel } = Collapse;

const CreateService = () => {
  const history = useHistory();
  return (
    <div className="createServiceClass">
      <AppBar
        onBack={() => history.push(routes.SERVICES)}
        dropDownMenu={
          <Menu>
            <Link to={routes.CREATE_SERVICE}>
              <Menu.Item key="delete">Add</Menu.Item>
            </Link>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Service Management</h3>
        </div>
      </AppBar>
      <div className="servicesCreateContent">
        <Collapse
          defaultActiveKey={["1"]}
          ghost
          expandIconPosition="right"
          bordered
        >
          <Panel header="Services List" key="1">
            <Checkbox
            //   checked={this.state.checked}
            //   disabled={this.state.disabled}
            //   onChange={this.onChange}
            >
              Service 1
            </Checkbox>
            <Checkbox
            //   checked={this.state.checked}
            //   disabled={this.state.disabled}
            //   onChange={this.onChange}
            >
              Service 2
            </Checkbox>
            <Checkbox
            //   checked={this.state.checked}
            //   disabled={this.state.disabled}
            //   onChange={this.onChange}
            >
              Service 3
            </Checkbox>
          </Panel>
        </Collapse>
        <Button className="addServiceBtn" type="default">
          Add Selected Services to Store
        </Button>
      </div>
    </div>
  );
};

export default CreateService;
