import { message } from "antd";
import { logError } from "../../../common/utils";
import {
  getStockIncomeReport,
  getProductIncomeReport,
  getServiceIncomeReport,
  getStockIncomeReportTimeline,
  getServiceIncomeReportTimeline,
  getProductIncomeReportTimeline,
} from "../../../infrastructure/reports";
import initialState from "./initialState";

const actions = {
  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  setReportData:
    (reportData) =>
    ({ setState }) => {
      setState({ reportData });
    },

  handleGetTimelineReports:
    (type, timeline) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        let response = "";
        const startDate = timeline[0].format("YYYY-MM-DD");
        const endDate = timeline[1].format("YYYY-MM-DD");
        if (type === "stockIncome") {
          response = await getStockIncomeReportTimeline(startDate, endDate);
        } else if (type === "serviceIncome") {
          response = await getServiceIncomeReportTimeline(startDate, endDate);
        } else if (type === "productIncome") {
          response = await getProductIncomeReportTimeline(startDate, endDate);
        }
        console.log(type, response);
        dispatch(actions.setReportData(response?.data));
      } catch (error) {
        logError(error, "@ handleGetReportData()");
        message.error("Some error occured !");
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  handleGetReportData:
    (type) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        let response = "";
        if (type === "stockIncome") {
          response = await getStockIncomeReport();
        } else if (type === "serviceIncome") {
          response = await getServiceIncomeReport();
        } else if (type === "productIncome") {
          response = await getProductIncomeReport();
        }
        console.log(type, response);
        dispatch(actions.setReportData(response?.data));
      } catch (error) {
        logError(error, "@ handleGetReportData()");
        message.error("Some error occured !");
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  resetStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

export default actions;
