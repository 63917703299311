import React from "react";
import { Table } from "antd";

const SparesTable = ({ cart }) => {
  const columns = [
    {
      title: "Item",
      dataIndex: "spare_name",
      key: "spare_name",
      render: (val) => <p>{val}</p>,
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
  ];

  return <Table columns={columns} dataSource={cart} />;
};

export default SparesTable;
