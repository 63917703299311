import "./index.scss";

import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { useInventoryStore } from "../../../Stores/Inventory";
import Loader from "../../../Common/UI/Loader";

const AddForm = () => {
  const [
    { loadingInventoryList, inventoryList, isAddingInventory, inventory },
    { handleAddInventory },
  ] = useInventoryStore();

  return (
    <Form
      className="inventoryAddFormWrapper"
      onFinish={(values) =>
        handleAddInventory({
          ...values,
          product_variant: inventory?.product_variant,
        })
      }
    >
      {loadingInventoryList ? (
        <Loader size="small" tip="Loading inventory data..." />
      ) : (
        <>
          <Form.Item label="Product" name="product_variant">
            <Select placeholder="Product">
              {inventoryList.map((opt) => (
                <Select.Option key={opt.id} value={opt?.id}>
                  {opt.product}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Manufacturing Date" name="mfg_date">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Expiry Date" name="exp_date">
            <DatePicker />
          </Form.Item>
          <Form.Item label="UPC No." name="exp_date">
            <Input />
          </Form.Item>
          <Form.Item label="Serial No." name="serial_number">
            <Input />
          </Form.Item>
          <Form.Item label="Price" name="purchase_cost">
            <InputNumber min={1} />
          </Form.Item>
          <br />
          <Form.Item className="inventoryAddForm-submit">
            <Button
              loading={isAddingInventory}
              htmlType="submit"
              type="primary"
            >
              Add to Inventory
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default AddForm;
