import React from "react";
import { Line } from "@ant-design/plots";
const LineChart = () => {
  const data = [
    {
      year: "11",
      value: 3,
    },
    {
      year: "12",
      value: 4,
    },
    {
      year: "13",
      value: 3.5,
    },
    {
      year: "14",
      value: 5,
    },
    {
      year: "15",
      value: 4.9,
    },
    {
      year: "16",
      value: 6,
    },
    {
      year: "17",
      value: 7,
    },
    {
      year: "18",
      value: 9,
    },
    {
      year: "19",
      value: 13,
    },
  ];
  const config = {
    data,
    xField: "year",
    yField: "value",
    color: "#FF6F00",
    height: 120,
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
  };

  return <Line {...config} />;
};

export default LineChart;
