import "./index.scss";
import { useState, useEffect } from "react";
import { Drawer } from "antd";
import FeatherIcon from "feather-icons-react";
import SideNav from "../../Common/UI/SideNav";
import BasicChart from "./BasicChart";
import BasicLine from "./BasicLine/LineChart";
import RingProcess from "./RingProcess";
import { useDashboardStore } from "../../Stores/Dashboard";
import routes from "../../Common/routes";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

const Home = () => {
  const [{ listData, assigned, weekData }, { getReport, getPercent }] =
    useDashboardStore();
  const [menuOpen, setMenuOpen] = useState(false);
  const [total, settotal] = useState(0);

  // useEffect(async () => {
  //   await getReport();
  //   getPercent();
  // }, [getReport, getPercent]);

  // console.log(weekData, "wweeell dfatas");

  // useEffect(() => {
  //   console.log(listData, "arrau wtj some");
  //   var total_sum = listData?.reduce((tot, arr) => {
  //     // return the sum with previous value
  //     return tot + arr?.total_job_amount;
  //     // set initial value as 0
  //   }, 0);
  //   settotal(total_sum);
  // }, [listData]);

  console.log(assigned, "assigned");
  return (
    <div className="autoHome">
      <div className="autoHome-menuIcon">
        <FeatherIcon
          icon="align-left"
          className="autoMenuIcon"
          onClick={() => setMenuOpen((prev) => !prev)}
        />
        <Drawer
          placement="left"
          visible={menuOpen}
          onClose={() => setMenuOpen(false)}
          closable
          closeIcon={
            <FeatherIcon className="autoMenuIcon" icon="align-right" />
          }
          width={400}
        >
          <SideNav />
        </Drawer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "12px",
        }}
      >
        <h3 className="autoHome-header">Dashboard</h3>
        <Link to={routes.MANUAL_CARD}>
          <button className="manual_jobcard create_job_btn">
            Create Job Card
          </button>
        </Link>
      </div>
      <div className="chart_wrap">
        <Row gutter={16} style={{ width: "100%" }}>
          <Col span={12} xs={20} sm={20} md={12} lg={12} xl={12}>
            <div>
              <div className="chart_part1">
                <RingProcess percent={assigned?.percentage} />
                <span>
                  <h6>Service crew</h6>
                  <h5>
                    {assigned?.assigned_employees}/{assigned?.total_employees}
                  </h5>
                  <h6>assigned</h6>
                </span>
              </div>
              <div className="weekly_service line_chart">
                <h4>Top Service</h4>
                <span>
                  <div style={{ paddingRight: "12px" }}>
                    <h6>Service 1</h6>
                    <h5>788</h5>
                  </div>
                  <BasicLine />
                </span>
                <span>
                  <div style={{ paddingRight: "12px" }}>
                    <h6>Service 2</h6>
                    <h5>388</h5>
                  </div>
                  <BasicLine />
                </span>
                <span>
                  <div style={{ paddingRight: "12px" }}>
                    <h6>Service 3</h6>
                    <h5>388</h5>
                  </div>
                  <BasicLine />
                </span>
                <span>
                  <div style={{ paddingRight: "12px" }}>
                    <h6>Service 4</h6>
                    <h5>388</h5>
                  </div>
                  <BasicLine />
                </span>
                <span>
                  <div style={{ paddingRight: "12px" }}>
                    <h6>Service 5</h6>
                    <h5>388</h5>
                  </div>
                  <BasicLine />
                </span>
              </div>
            </div>
          </Col>
          <Col span={12} xs={20} sm={20} md={12} lg={12} xl={12}>
            <div>
              <div className="weekly_service">
                <h4>Total Service</h4>
                <BasicChart />
              </div>
              <div className="weekly_service daily_report">
                <span className="daily_report_title">
                  <h4>Daily Report</h4>
                  <Link to={routes.DAILY_REPORT}>
                    <FeatherIcon icon="arrow-right" />
                  </Link>
                </span>

                <div>
                  <span>
                    <h6>Total jobs done</h6>
                    <h5>{listData?.length}</h5>
                  </span>
                  <span>
                    <h6>Total service cost</h6>
                    <h5>{total}</h5>
                  </span>
                  <span>
                    <h6>Total product cost</h6>
                    <h5>0</h5>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
