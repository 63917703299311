import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";
import config from "../../../common/config";

// This is required in one file to make the chrome devtools for visible.
defaults.devtools = true;

const AuthStore = createStore({
  name: `automoto_store_${config.ENV}_auth`,
  initialState,
  actions,
});

export const useAuthStore = createHook(AuthStore, {
  selector: (state) => state,
});
