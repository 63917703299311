import routes from "../../routes";

export const NavLinks = [
  {
    key: 1,
    route: routes.LIST_INVENTORY,
    icon: "database",
    title: "Inventory Management",
  },
  {
    key: 2,
    route: routes.LIST_JOB_CARD,
    icon: "layers",
    title: "Manage Job Card",
  },
  {
    key: 3,
    route: routes.INSURANCE,
    icon: "tool",
    title: "Insurance",
  },
  {
    key: 4,
    route: routes.SERVICES,
    icon: "tool",
    title: "Manage Services",
  },
  {
    key: 5,
    route: routes.EMPLOYEE,
    icon: "users",
    title: "Employee Management",
  },
  // {
  //   key: 5,
  //   route: routes.VENDOR,
  //   icon: "archive",
  //   title: "Vendor Management",
  // },
  {
    key: 6,
    route: routes.LIST_BAY,
    icon: "archive",
    title: "Bay Management",
  },
  {
    key: 7,
    route: routes.LIST_REPORTS,
    icon: "file-text",
    title: "Reports",
  },
  {
    key: 8,
    route: routes.VIEW_PROFILE,
    icon: "user",
    title: "Profile",
  },
];
