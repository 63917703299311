import { get, post } from "../common/remote";

export const getServiceCategory = () => {
  return get("/stores/as-store-service/list-view/");
};
export const saveIncentiveBonus = (id, values) => {
  return post(`/stores/set-service-reward/${id}/`, values);
};

export const getIncentiveBonus = (id) => {
  return get(`/stores/service-reward-view/${id}/`);
};
export const setServicePrice = (values) => {
  return post(`/stores/add-serviceprice-to-asstore/`, values);
};
