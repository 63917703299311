import React from "react";
import { Column } from "@ant-design/plots";
import { useDashboardStore } from "../../../Stores/Dashboard";

const Index = () => {
  const [{ weekData }, {}] = useDashboardStore();

  const config = {
    data: weekData !== null && weekData?.data,
    xField: "day",
    yField: "count",
    color: "#FF6F00",
    columnWidthRatio: 0.8,
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "data",
      },
      sales: {
        alias: "Services",
      },
    },
  };
  return <Column {...config} />;
};

export default Index;
