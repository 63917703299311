import "./index.scss";

import { Popover } from "antd";

import { FeatherIcon } from "../../../../Common/Assets";
import BackButton from "../../../../Common/UI/BackButton";

const JobAppBar = ({ onBack, title, dropDownMenu }) => {
  return (
    <div className="jobAppbar">
      <div className="jobAppbar-mid">
        <div className="jobAppbar-left">
          <BackButton onClick={onBack} />
        </div>
        {title}
      </div>
      {dropDownMenu && (
        <div className="jobAppbar-right">
          <Popover content={dropDownMenu}>
            <FeatherIcon icon="more-vertical" size={20} />
          </Popover>
        </div>
      )}
    </div>
  );
};

export default JobAppBar;
