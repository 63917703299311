import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";
import config from "../../../common/config";

const BayStore = createStore({
  name: `automoto_store_${config.ENV}_bay`,
  initialState,
  actions,
});

export const useBayStore = createHook(BayStore, {
  selector: (state) => state,
});
