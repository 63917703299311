import "./index.scss";

import { DatePicker, Menu } from "antd";

import BackButton from "../../../Common/UI/BackButton";
import JobAppBar from "../Common/JobAppBar";
import jobcards from "../List/jobcards.json";
import JobListCard from "../Common/JobListCard";

const JobCardHistory = () => {
  return (
    <div className="jobCardHistoryContainer">
      <JobAppBar
        title={
          <>
            <BackButton size={20} onClick={() => window.history.back()} />
            <p>History</p>
          </>
        }
        dropDownMenu={<Menu></Menu>}
      />
      <div className="jobCardHistory-date">
        <DatePicker />
      </div>
      <div className="jobListCards">
        <h3 className="jobCardHistory-title">Service Details</h3>
        {jobcards.map((jobcard) => (
          <JobListCard key={jobcard?.id} jobcard={jobcard} />
        ))}
        {jobcards.map((jobcard) => (
          <JobListCard key={jobcard?.id} jobcard={jobcard} />
        ))}
      </div>
    </div>
  );
};

export default JobCardHistory;
