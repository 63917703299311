import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";
import config from "../../../common/config";

const ServicesStore = createStore({
  name: `automoto_store_${config.ENV}_services`,
  initialState,
  actions,
});

export const useServiceStore = createHook(ServicesStore, {
  selector: (state) => state,
});
