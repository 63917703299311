export const getJobcardStatus = (jobcard) => {
  if (jobcard?.status === "delivered") return "delivered";
  else if (jobcard?.status === "rejected") return "rejected";
  else if (jobcard?.status === "pending") return "pending";
  else if (jobcard?.status === "inprogress") return "inprogress";
  else if (jobcard?.status === "accepted") return "accepted";
  else if (jobcard?.status === "completed") return "completed";


  return "pending";
};
