import { Button, Col, Drawer, Form, Upload } from "antd";
import { Input } from "antd";
import React from "react";
import { carview } from "../../../../Common/Assets";
import { useState, useEffect } from "react";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import "./index.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useCamera } from "@capacitor-community/react-hooks/camera";
// import { CameraResultType } from "@capacitor/core";
import { useCallback } from "react";
import { Camera, CameraResultType } from "@capacitor/camera";
import { DeleteOutlined } from "@ant-design/icons";

const Index = ({ id, status, imageVal }) => {
  const { TextArea } = Input;
  const [carForm] = Form.useForm();
  const [carActive, setCarActive] = useState("");
  const { photo, getPhoto } = useCamera();
  const [capturedPhoto, setcapturedPhoto] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [
    { UserCarData, visible, render },
    { postCarImage, setVisible, setRerender, deleteImageRemark },
  ] = useJobcardStore();

  const triggerCamera = useCallback(async () => {
    try {
      const image = Camera.getPhoto({
        quality: 20,
        allowEditing: false,
        resultType: CameraResultType.Uri,
      })
        .then(async (res) => {
          console.log({ webPath: res.webPath });
          console.log(res, "image valueee");
          setImageUrl(res.webPath);
          let blob = await fetch(res.webPath).then((r) => r.blob());

          var last = res.webPath.substring(
            res.webPath.lastIndexOf("/") + 1,
            res.webPath.length
          );
          const newFile = new File([blob], `${last}.${res?.format}`, {
            type: `image/${res?.format}`,
          });
          console.log("file", newFile);
          setcapturedPhoto(newFile);
        })
        .catch((er) => console.log(er));
      console.log(image.webPath, "image url");

      // Can be set to the src of an image now
      // imageElement.src = imageUrl;
    } catch (er) {
      console.log(er);
    }
  }, [getPhoto]);

  const getCarActiveName = () => {
    if (carActive === "front") {
      return "Front Side";
    } else if (carActive === "front_left") {
      return "Front Left Side";
    } else if (carActive === "front_right") {
      return "Front Right Side";
    } else if (carActive === "side_left") {
      return "Left Side";
    } else if (carActive === "side_right") {
      return "Right Side";
    } else if (carActive === "rear") {
      return "Rear Side";
    } else if (carActive === "rear_left") {
      return "Rear Left Side";
    } else if (carActive === "rear_right") {
      return "Rear Right Side";
    } else if (carActive === "top") {
      return "Top Side";
    }
  };

  const onDrawerClose = () => {
    setVisible(false);
  };

  const isCarHasImage = (name) => {
    var check = false;
    UserCarData?.vehicle_views?.forEach((element) => {
      element?.position === name && (check = true);
    });
    return check ? true : false;
  };
  const getCarImage = (name) => {
    var image = [];
    var remark = "";
    var viewId = "";
    UserCarData?.vehicle_views?.forEach((element) => {
      element?.position === name && (image = element?.photos);
      element?.position === name && (remark = element?.remarks);
      element?.position === name && (viewId = element?.id);
    });
    return { image, remark, viewId };
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Col xs={24} sm={24} md={12} className="jobCardView-col-right">
      <div className="jobCardView-right">
        <span className="jobCardView-right">
          <img style={{ maxWidth: "100px" }} src={carview} alt="car view" />
          <div
            className={
              isCarHasImage("front")
                ? "jobCardView-circle-active jobCardView-circle-front"
                : "jobCardView-circle jobCardView-circle-front"
            }
            onClick={() => {
              setCarActive("front");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("rear")
                ? "jobCardView-circle-active jobCardView-circle-rear"
                : "jobCardView-circle jobCardView-circle-rear"
            }
            onClick={() => {
              setCarActive("rear");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("side_left")
                ? "jobCardView-circle-active jobCardView-circle-side_left"
                : "jobCardView-circle jobCardView-circle-side_left"
            }
            onClick={() => {
              setCarActive("side_left");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("side_right")
                ? "jobCardView-circle-active jobCardView-circle-side_right"
                : "jobCardView-circle jobCardView-circle-side_right"
            }
            onClick={() => {
              setCarActive("side_right");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("top")
                ? "jobCardView-circle-active jobCardView-circle-top"
                : "jobCardView-circle jobCardView-circle-top"
            }
            onClick={() => {
              setCarActive("top");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("front_left")
                ? "jobCardView-circle-active jobCardView-circle-front_left"
                : "jobCardView-circle jobCardView-circle-front_left"
            }
            onClick={() => {
              setCarActive("front_left");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("front_right")
                ? "jobCardView-circle-active jobCardView-circle-front_right"
                : "jobCardView-circle jobCardView-circle-front_right"
            }
            onClick={() => {
              setCarActive("front_right");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("rear_left")
                ? "jobCardView-circle-active jobCardView-circle-rear_left"
                : "jobCardView-circle jobCardView-circle-rear_left"
            }
            onClick={() => {
              setCarActive("rear_left");
              status && setVisible(true);
            }}
          ></div>
          <div
            className={
              isCarHasImage("rear_right")
                ? "jobCardView-circle-active jobCardView-circle-rear_right"
                : "jobCardView-circle jobCardView-circle-rear_right"
            }
            onClick={() => {
              setCarActive("rear_right");
              status && setVisible(true);
            }}
          ></div>
        </span>
        <Drawer
          title={getCarActiveName()}
          placement="right"
          onClose={onDrawerClose}
          visible={visible}
          className="jobCardView-drawer"
          width={420}
        >
          <Form
            form={carForm}
            layout="vertical"
            requiredMark={false}
            onFinish={async (values) => {
              console.log(carActive, "car active");
              await postCarImage(
                UserCarData?.id,
                values,
                carActive,
                capturedPhoto
              );
              carForm.resetFields();
              setRerender(render);
              window.location.reload();
            }}
          >
            <Form.Item
              name="photo"
              // rules={[{ required: true, message: "Photo is required" }]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {imageUrl && (
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      marginRight: "8px",
                      borderRadius: "12px",
                    }}
                    src={imageUrl}
                  ></img>
                )}
                <div
                  className="upload_btn"
                  style={{ color: "#fff" }}
                  onClick={() => triggerCamera()}
                >
                  Upload
                </div>
                {/* <Upload
                  accept="image/*"
                  customRequest={(file, onSuccess) =>
                    dummyRequest(file, onSuccess)
                  }
                  maxCount={1}
                  listType="picture-card"
                >
                  <div style={{ marginTop: 8, color: "#fff" }}>Upload</div>
                </Upload> */}
              </div>
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                position: "relative",
              }}
            >
              {isCarHasImage(carActive) &&
                getCarImage(carActive)?.image?.map((res) => (
                  <div style={{ position: "relative" }}>
                    <span
                      onClick={() =>
                        deleteImageRemark(
                          isCarHasImage(carActive) &&
                            getCarImage(carActive)?.viewId,
                          res.id
                        )
                      }
                      className="remark_img_dlt"
                      style={{ position: "absolute", right: "0", top: "0" }}
                    >
                      <DeleteOutlined />
                    </span>
                    <Zoom overlayBgColorEnd={`rgba(0, 0, 0,0.5)`}>
                      <img
                        style={{
                          width: "120px",
                          height: "120px",
                          objectFit: "cover",
                          marginRight: "8px",
                          borderRadius: "12px",
                        }}
                        className="side_image"
                        src={process.env.REACT_APP_BASE_API + res?.image}
                      ></img>
                    </Zoom>
                  </div>
                ))}
            </div>

            <p style={{ color: "white" }}>
              {isCarHasImage(carActive) && getCarImage(carActive)?.remark}
            </p>
            <Form.Item
              name="remarks"
              // rules={[{ required: true, message: "Remark is required" }]}
            >
              <TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                placeholder="Remarks"
                maxLength={500}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Upload
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    </Col>
  );
};

export default Index;
