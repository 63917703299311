import { Drawer } from "antd";
import { useState } from "react";
import sizeMe from "react-sizeme";
import FeatherIcon from "feather-icons-react";
import StackGrid from "react-stack-grid";
import { Link } from "react-router-dom";
import routes from "../../Common/routes";
import StackCard from "../../Common/UI/StackCard";
import SideNav from "../../Common/UI/SideNav";

const ListReports = ({ size: { width } }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className="employeeDashMain">
      <div className="employeeDashTopMenu">
        <FeatherIcon
          icon="align-left"
          onClick={() => setDrawerOpen(true)}
          className="employeeDashMenuIcon"
        />
        <Drawer
          placement="left"
          visible={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          closable
          closeIcon={
            <FeatherIcon className="employeeDashMenuIcon" icon="align-right" />
          }
          width={400}

        >
          <SideNav />
        </Drawer>
      </div>
      <div className="employeeDashContent">
        <div className="employeeContentTitle">Reports</div>
        <div className="employeeCardsStack">
          <StackGrid
            columnWidth={width < 425 ? "50%" : "33.33%"}
            gutterHeight={10}
            gutterWidth={5}
          >
            {/* <Link to={routes.VIEW_REPORT("stockIncome")}>
              <StackCard label="Stock Income Report" />
            </Link> */}
            <Link to={routes.VIEW_REPORT("serviceIncome")}>
              <StackCard label="Service Income Report" />
            </Link>
            {/* <Link to={routes.VIEW_REPORT("productIncome")}>
              <StackCard label="Product Income Report" />
            </Link> */}
          </StackGrid>
        </div>
      </div>
    </div>
  );
};

export default sizeMe()(ListReports);
