import { Button, Select, Row, Col, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import JobAppBar from "../../Common/JobAppBar";
import { useHistory } from "react-router-dom";
import { useServiceStore } from "../../../../Stores/Services";
import TableCart from "./Tables/TableData";
import Loader from "../../../../Common/UI/Loader";
import { useLocation } from "react-router-dom";
import UserData from "./UserData";
import ProductsTable from "./Tables/ProductsTable";
import Remarks from "../Remarks/Index";
import routes from "../../../../Common/routes";
import { DeleteOutlined } from "@ant-design/icons";
import { InlineInputEdit } from "react-inline-input-edit";
import { useEmployeeStore } from "../../../../Stores/Employee";
import { Link } from "react-router-dom";

const ServiceSelect = () => {
  const [
    { visibleAdvance, UserCarData, loader, jobCardId, jobCardData, render },
    {
      addServiceCart,
      getUserCarData,
      confirmUserData,
      viewSingleJob,
      setRerender,
      setServiceStatus,
      setVisibleAdvance,
      addAdvance,
      addNewRemark,
      deleteRemarksSingle,
      editNewRemark,
      setFloorManage,
    },
  ] = useJobcardStore();
  const [{ serviceCategory }, { getServiceCategory }] = useServiceStore();
  const [{ employeeList, loading }, { getEmployeeList }] = useEmployeeStore();

  const history = useHistory();
  const location = useLocation();
  const { Option } = Select;
  const [userVisible, setuserVisible] = useState(false);
  const [advance, setadvanceamount] = useState("");
  const [remarkValue, setRemarkValue] = useState("");

  var array = [];

  useEffect(() => {
    if (location.state?.customer_id !== undefined) {
      getUserCarData(location.state?.customer_id);
    } else {
      getUserCarData(location.state?.id);
    }
    getServiceCategory();
    getEmployeeList();
  }, [getServiceCategory, render]);

  useEffect(() => {
    viewSingleJob(UserCarData?.id);
    console.log(jobCardData, "jobcard dataaa");
  }, [UserCarData, render]);


  const _handleFocus = (text) => {
    console.log("Focused with text: " + text);
  };

  jobCardData?.jobcardservices?.forEach((element) => {
    element?.spares?.forEach((res) => {
      array.push(res);
    });
  });

  const onChange = (value) => {
    if (jobCardId === null) {
      addServiceCart(jobCardData.id, value);
      // setRerender(render);
    } else {
      addServiceCart(jobCardId, value);
      // setRerender(render);
    }
  };
  const onChangeEmployee = (value) => {
    console.log(value);
    setFloorManage(value, jobCardData?.id);
  };

  return (
    <div
      className="jobCardView"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <JobAppBar onBack={() => history.goBack()} title="Manual jobcard" />
      {loader ? (
        <Loader />
      ) : (
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12}>
            <UserData UserCarData={UserCarData} />
          </Col>
          {jobCardData && (
            <Remarks
              id={location?.state?.customer_id}
              status={true}
              imageVal={false}
            />
          )}
        </Row>
      )}
      {!jobCardData && (
        <div className="cart_jobcart">
          <Button
            type="primary"
            onClick={() => {
              confirmUserData(UserCarData?.id);
              setuserVisible(true);
            }}
          >
            Confirm
          </Button>
        </div>
      )}
      {jobCardData && (
        <div>
          <h3 className="jobCardView-title">Select services</h3>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Select a service"
            optionFilterProp="children"
            onChange={onChange}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {serviceCategory !== null ? (
              serviceCategory?.map((item, key) => (
                <Option value={item?.service}>
                  {item?.service_details?.name}
                </Option>
              ))
            ) : (
              <Loader tip="Loading services" />
            )}
          </Select>
          <button
            style={{ color: "black" }}
            onClick={() => {
              history.push(routes.VIEW_SINGLE_REPORT_REMARK(jobCardData?.id));
            }}
          >
            View Jobcard
          </button>

          <div style={{ paddingTop: "45px" }} id="cart_table">
            <a
              style={{
                color: "#FF6F00",
                fontSize: "18px",
                paddingBottom: "12px",
              }}
              target="_blank"
              href="https://www.indiafilings.com/find-gst-rate"
            >
              GST Lookup
            </a>
            <TableCart cart={jobCardData?.jobcardservices} user={jobCardData} />
          </div>
          <h3 className="jobCardView-title">
            Assign Floor Manager
            <span>:&nbsp;&nbsp;{jobCardData?.floor_manager}</span>
          </h3>
          {jobCardData?.floor_manager === null && (
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="Select floor manager"
              optionFilterProp="children"
              onChange={onChangeEmployee}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {employeeList !== null ? (
                employeeList?.map((item, key) => (
                  <Option value={item?.name}>{item?.name}</Option>
                ))
              ) : (
                <Loader tip="Loading services" />
              )}
            </Select>
          )}
          <h3 className="jobCardView-title">Products</h3>

          <div style={{ paddingTop: "34px" }} id="cart_table">
            <ProductsTable cart={array} user={jobCardData} />
          </div>
          <h3 className="jobCardView-title">Additional remarks</h3>
          <div style={{ paddingTop: "12px" }}>
            {jobCardData?.comments?.map((res) => (
              <span className="wrap_remark">
                <InlineInputEdit
                  text={String(res.remarks)}
                  inputWidth="100px"
                  inputHeight="25px"
                  inputMaxLength={50}
                  onFocus={_handleFocus}
                  onFocusOut={(text) => editNewRemark(text, res.id)}
                />

                <button
                  onClick={() => deleteRemarksSingle(res.id)}
                  style={{ background: "transparent", border: "none" }}
                >
                  <DeleteOutlined />
                </button>
              </span>
            ))}
          </div>

          <div className="remark_add_wrap">
            <input
              onChange={(e) => setRemarkValue(e.target.value)}
              className="remark_input_field"
              placeholder="Remarks"
              value={remarkValue}
            ></input>
            <button
              onClick={() => {
                addNewRemark(remarkValue, jobCardData?.id);
                setRemarkValue("");
              }}
            >
              Add remarks
            </button>
          </div>
          {jobCardData?.status !== "accepted" ? (
            <div className="cart_jobcart">
              <Button
                type="primary"
                onClick={() => {
                  setVisibleAdvance(true);
                  setServiceStatus(jobCardData?.id, "accepted");
                }}
              >
                Accept
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setServiceStatus(jobCardData?.id, "rejected");
                  history.push(`/jobcard/list`);
                }}
              >
                Reject
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to={routes.VIEW_JOB_CARD(jobCardData?.id)}>
                <Button type="primary">Continue</Button>
              </Link>
            </div>
          )}
        </div>
      )}

      <Modal
        title={"Add advance amount"}
        visible={visibleAdvance}
        onOk={() => {
          addAdvance(jobCardData?.id, advance);
          setServiceStatus(jobCardData?.id, "accepted");
          setVisibleAdvance(false);
          history.push(`/jobcard/list`);
        }}
        onCancel={() => setVisibleAdvance(false)}
      >
        <Input
          placeholder="amount"
          min={0}
          onChange={(e) => setadvanceamount(e.target.value)}
        ></Input>
      </Modal>
    </div>
  );
};

export default ServiceSelect;
