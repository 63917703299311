import { logError } from "../../../common/utils";
import { getProfile } from "../../../infrastructure/profile";

const actions = {
  setLoadingProfile:
    (loadingProfile) =>
    ({ setState }) => {
      setState({ loadingProfile });
    },

  setProfile:
    (profile) =>
    ({ setState }) => {
      setState({ profile });
    },

  getProdileData:
    () =>
    async ({ setState, dispatch }) => {
      try {
        console.log("profile data ...........");
        const res = await getProfile();
        setState({ profile: res.data });
        console.log(res, "store list");
      } catch (error) {
        logError(error, " @ getEmployeeList()");
        // message.error(error.toString());
      } finally {
        // dispatch(actions.setLoading(false));
      }
    },
};

export default actions;
