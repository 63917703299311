import AppBar from "../../../Common/UI/AppBar";
import { useHistory } from "react-router-dom";
import ProfileForm from "../Form";
import { Menu } from "antd";
import routes from "../../../Common/routes";

const ProfileEdit = () => {
  const history = useHistory();
  return (
    <>
      <AppBar
        onBack={() => history.push(routes.VIEW_PROFILE)}
        dropDownMenu={<Menu></Menu>}
      >
        <div className="autoAppbar-title">
          <h3>Edit Store Profile</h3>
        </div>
      </AppBar>
      <ProfileForm />
    </>
  );
};

export default ProfileEdit;
