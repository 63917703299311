import { message } from "antd";
import { logError } from "../../../common/utils";
import {
  addEmployee,
  deleteEmployee,
  editEmployee,
  getEmployeeList,
  listAttendance,
} from "../../../infrastructure/employee";
import routes from "../../Common/routes";
import { convertObjectToFormData } from "../../Common/Utils/convertToFormData";

const actions = {
  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  setCurrentEmployee:
    (currentEmployee) =>
    ({ setState }) => {
      setState({ currentEmployee });
    },

  getEmployee:
    (id, setLoading) =>
    async ({ setState }) => {
      try {
        const employeeList = await getEmployeeList();
        if (employeeList.data) {
          setState({
            currentEmployee: employeeList?.data?.filter(
              (employee) => employee?.id === id
            )[0],
          });
        } else {
          message.error("Could not fetch employee details.");
        }
      } catch (error) {
        logError(error, " @ getEmployee()");
        message.error(error.toString());
      } finally {
        setLoading(false);
      }
    },

  getEmployeeList:
    () =>
    async ({ setState, dispatch }) => {
      try {
        const employeeList = await getEmployeeList();
        if (employeeList.data) {
          setState({ employeeList: employeeList?.data });
        } else {
          message.error("Could not fetch employee details.");
        }
      } catch (error) {
        logError(error, " @ getEmployeeList()");
        message.error(error.toString());
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  editEmployee: (data, setLoading, id, history) => async () => {
    try {
      const newData = {
        ...data,
        dob: data?.dob.format("YYYY-MM-DD"),
      };
      const formData = convertObjectToFormData(newData);
      const response = await editEmployee(formData, id);
      if (response?.StatusCode === 6000) {
        console.log(`response`, response);
        message.success(response?.data?.message);
        history.push(routes.LIST_EMPLOYEE("random"));
      } else {
        logError(response, " @editEmployee");
        message.error(response?.data?.message);
      }
    } catch (error) {
      logError(error, " @ editEmployee()");
      message.error(error.toString());
    } finally {
      setLoading(false);
    }
  },

  createEmployee: (data, setLoading, history) => async () => {
    try {
      const newData = {
        ...data,
        dob: data?.dob.format("YYYY-MM-DD"),
      };
      const formData = convertObjectToFormData(newData);
      const response = await addEmployee(formData);
      console.log(response, "yello");

      if (response?.Statuscode === 6000) {
        message.success(response?.message);
        history.push(routes.LIST_EMPLOYEE("random"));
      } else {
        logError(response, " @createEmployee");
        message.error(response?.data?.phone?.message);
      }
    } catch (error) {
      logError(error, " @ createEmployee()");
      message.error(error.toString());
    } finally {
      setLoading(false);
    }
  },

  deleteEmployee:
    (employeeId, history) =>
    async ({ dispatch }) => {
      try {
        const response = await deleteEmployee(employeeId);
        console.log(`response`, response);
        if (response.StatusCode === 6000) {
          message.success("Employee deleted.");
          history.push(routes.LIST_EMPLOYEE("random"));
        } else {
          message.warning("Employee could not be deleted");
        }
      } catch (error) {
        logError(error, " @deleteEmployee");
      }
    },

  listpunch:
    (id) =>
    async ({ setState }) => {
      var data = await listAttendance(id);
      console.log("punch in data", data);
      setState({ listData: data.data });
    },
};

export default actions;
