import { Modal } from "antd";

const BayDelete = (onOk) => {
  return Modal.confirm({
    content: <p>Are you sure you want to delete this bay ?</p>,
    okButtonProps: { type: "danger" },
    okText: "Delete Bay",
    centered: true,
    onOk,
  });
};

export default BayDelete;
