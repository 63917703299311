const routes = {
  LOGIN: `/`,
  AUTH_RESET: `/authreset`,
  HOME: `/home`,
  CREATE_INVENTORY: `/inventory/create`,
  LIST_INVENTORY: `/inventory/list`,
  VIEW_INVENTORY: (inventoryId) => `/inventory/view/${inventoryId}`,
  EDIT_INVENTORY: (inventoryId) => `/inventory/edit/${inventoryId}`,
  INSURANCE: `/insurance`,
  LIST_JOB_CARD: `/jobcard/list`,
  HISTORY_JOB_CARD: `/jobcard/history`,
  VIEW_JOB_CARD: (jobId) => `/jobcard/view/${jobId}`,
  VIEW_JOB_CARD_REPORT: (jobId) => `/jobcard/view/report/${jobId}`,
  VIEW_SINGLE_REPORT: (jobId) => `/jobcard/single/report/${jobId}`,
  VIEW_SINGLE_REPORT_REMARK: (jobId) =>
    `/jobcard/single/remark-report/${jobId}`,
  SERVICES: `/services`,
  EXPANDED_SERVICE: (serviceId) => `/services/expanded/${serviceId}`,
  CREATE_SERVICE: `/services/create`,

  VENDOR: `/vendor`,
  LIST_VENDOR: (categoryId) => `/vendor/list/${categoryId}`,
  EXPANDED_VENDOR: (vendorId) => `/vendor/expanded/${vendorId}`,
  EDIT_VENDOR: (vendorId) => `/vendor/edit/${vendorId}`,
  CREATE_VENDOR: `/vendor/create`,
  EMPLOYEE: `/employee`,
  LIST_EMPLOYEE: (categoryId) => `/employee/list/${categoryId}`,
  EXPANDED_EMPLOYEE: (employeeId) => `/employee/expanded/${employeeId}`,
  EDIT_EMPLOYEE: (employeeId) => `/employee/edit/${employeeId}`,
  CREATE_EMPLOYEE: `/employee/create`,

  LIST_BAY: `/bay/list`,
  VIEW_BAY: (bayId) => `/bay/view/${bayId}`,

  VIEW_PROFILE: `/profile/view`,
  CREATE_PROFILE: `/profile/create`,
  EDIT_PROFILE: `/profile/edit`,

  LIST_REPORTS: `/reports/list`,
  VIEW_REPORT: (reportType) => `/reports/list/${reportType}`,
  VIEW_ATTENDANCE: (employee_id) => `/employee/${employee_id}`,
  VIEW_MAP: `/employee/attendance/map  `,
  MANUAL_CARD: `/manual-card`,
  MANUAL_FINAL: `/manual-final`,
  DAILY_REPORT: `/daily-report`,
  VIEW_DETAIL_REPORT: (jobId) => `/list-report/${jobId}`,
};

export default routes;
