import "./index.scss";

import { Avatar, Menu } from "antd";
import { useHistory } from "react-router";

import AppBar from "../../../Common/UI/AppBar";
import routes from "../../../Common/routes";
import EmployeeForm from "../Form";
import { useEffect, useState } from "react";
import { useEmployeeStore } from "../../../Stores/Employee";
import Loader from "../../../Common/UI/Loader";

const EmployeeEdit = (props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [{ currentEmployee }, { getEmployee, setCurrentEmployee }] =
    useEmployeeStore();

  useEffect(() => {
    const { employeeId } = props?.match?.params;
    // See if the data of the current employee is present in the local state
    if (currentEmployee && currentEmployee?.id === employeeId) {
      setLoading(false);
    } else {
      // Set the currentEmployee object to null initially to clear data if already present.
      setCurrentEmployee(null);
      if (employeeId) getEmployee(employeeId, setLoading);
    }
  }, [currentEmployee, getEmployee, props?.match?.params, setCurrentEmployee]);

  if (loading) return <Loader tip="Loading employee details..." />;

  return (
    <div className="vendorEdit">
      <AppBar
        onBack={() => history.push(routes.LIST_EMPLOYEE("123"))}
        dropDownMenu={
          <Menu>
            <Menu.Item key="edit">Edit</Menu.Item>
            <Menu.Item key="delete">Delete</Menu.Item>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Edit</h3>
        </div>
      </AppBar>
      <div className="employeeEditContent">
        <div className="employeeEditAvatarContainer">
          <Avatar size={100} />
        </div>
        <EmployeeForm {...{ currentEmployee, purpose: "edit" }} />
      </div>
    </div>
  );
};

export default EmployeeEdit;
