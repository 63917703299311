const initialState = {
  bayList: [],
  currentBay: null,
  loadingCurrentBay: true,
  loadingBayList: true,
  isSubmitting: false,
  addModal: false,
  editModal: false,
};

export default initialState;
