import { get } from "../common/remote";

export const getServiceReport = () => {
  return get("/reports/store-service-dailyreport/");
};
export const getReportWeekly = () => {
  return get("/stores/service-graph/");
};
export const listAttendance = (id) => {
  return get(`/employee/store-attendance-view/${id}/`);
};
export const assignedEmployees = () => {
  return get(`/stores/assigned-service-crew/`);
};
