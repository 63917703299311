import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { useHistory } from "react-router";
import "./index.scss";
import { useEffect, useState } from "react";
import Loader from "../../../Common/UI/Loader";
import { useServiceStore } from "../../../Stores/Services";
// import ServiceExpandedCard from "./ServiceExapandedCard";
import { Input, Button, Form } from "antd";

const ServiceExpanded = ({ match }) => {
  const [serviceForm] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);

  const [
    { currentCategory, incentive, bonus },
    { setCurrentCategory, getCategory, getIncentiveBonus, saveIncentiveBonus },
  ] = useServiceStore();
  console.log(currentCategory);
  const history = useHistory(0);

  useEffect(() => {
    setCurrentCategory(null);
    const { serviceId } = match?.params;
    if (serviceId) getCategory(serviceId, setLoading);
    if (serviceId) getIncentiveBonus(serviceId, setLoading);
  }, [getCategory, getIncentiveBonus, match?.params, setCurrentCategory]);

  useEffect(() => {
    serviceForm.setFieldsValue({ incentive, bonus });
  }, [bonus, incentive, serviceForm]);

  if (loading) return <Loader tip="Getting service details" />;

  return (
    <div className="serviceExpandedClass">
      <AppBar
        onBack={() => history.push(routes.SERVICES)}
        // dropDownMenu={
        //   <Menu>
        //     <Link to={routes.CREATE_EMPLOYEE}>
        //       <Menu.Item key="delete">Create</Menu.Item>
        //     </Link>
        //   </Menu>
        // }
      >
        <div className="autoAppbar-title">
          <h3>{currentCategory?.name}</h3>
        </div>
      </AppBar>
      <div className="serviceContent">
        <table>
          <tbody>
            <tr>
              <td>Service ID</td>
              <td>{currentCategory?.service_id}</td>
            </tr>
            <tr>
              <td>Service name</td>
              <td>{currentCategory?.name}</td>
            </tr>
            <tr>
              <td>Service category</td>
              <td>{currentCategory?.category_name}</td>
            </tr>
          </tbody>
        </table>

        <div className="incentive-con">
          <Form
            form={serviceForm}
            layout="vertical"
            requiredMark={false}
            onFinish={(values) =>
              saveIncentiveBonus(currentCategory?.id, values, setPostLoading)
            }
          >
            <Form.Item label="Incentive" name="incentive">
              <Input
                placeholder="Set incentive amount"
                className="input-comp"
              />
            </Form.Item>
            <Form.Item label="Bonus" name="bonus">
              <Input placeholder="Set bonus amount" className="input-comp" />
            </Form.Item>
            <div className="btn-con">
              <Button
                type="primary"
                style={{ width: "100%" }}
                loading={postLoading}
                htmlType="submit"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ServiceExpanded;
