import { patch, post } from "../common/remote";

export const loginApi = (data) => {
  // without token
  return post("/users/store-admin-login/", data, false);
};

export const resetUsernameAndPasswordApi = (data) => {
  return patch("/users/store_reset_username&password/", data);
};
