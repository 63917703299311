import "./index.scss";
import "../index.scss";
import JobAppBar from "../Common/JobAppBar";
// import jobcard from "./jobcard.json";
// import JobInvoice from "./Common/JobInvoice";
import { useEffect, useState } from "react";
import { useJobcardStore } from "../../../Stores/Jobcard";
import { Link, useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import { Form, Select, Input, Button } from "antd";
import { MdClose, MdDone } from "react-icons/md";
import AddVehicle from "./AddVehicle";

const JobCardView = () => {
  const [
    { verfiedUser, status, userVehicles },
    { verifyUser, createProfile, setDefaultCar },
  ] = useJobcardStore();

  const [newUserDsiplay, setnewuUserDsiplay] = useState("none");
  const [mainDsiplay, setmainDsiplay] = useState("block");
  const history = useHistory();
  const [form] = Form.useForm();
  const [value, setvalues] = useState(null);

  useEffect(() => {}, []);
  const newUser = (data) => {
    console.log("first");
    createProfile(data);
    setvalues(data);
    setnewuUserDsiplay("flex");
    setmainDsiplay("none");
  };
  console.log(userVehicles, "verified user");
  return (
    <div className="jobCardViewContainer manualCardcontainer">
      <JobAppBar
        onBack={() =>
          newUserDsiplay === "none"
            ? history.goBack()
            : setnewuUserDsiplay("none") || setmainDsiplay("block")
        }
        title="Manual jobcard"
      />

      <div className="jobCardView" style={{ display: mainDsiplay }}>
        <h3 className="jobCardView-title">User Details</h3>
        <Form
          requiredMark={false}
          className="inventoryAddFormWrapper"
          form={form}
          onFinish={(values) => newUser(values) || form.resetFields()}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              name="number"
              rules={[{ required: true, message: "Mobile is required" }]}
            >
              <Input
                onChange={(e) => verifyUser(e.target.value)}
                placeholder={
                  verfiedUser !== null ? verfiedUser.phone : "Mobile number"
                }
              />
            </Form.Item>
            {status ? (
              <span>
                <button
                  className="verified"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    color: "white",
                  }}
                >
                  <MdDone />
                </button>
                <p style={{ color: "white", fontSize: "14px" }}>
                  User found in database
                </p>
              </span>
            ) : (
              status === false && (
                <span>
                  <button
                    className="verify_failed"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      color: "white",
                    }}
                  >
                    <MdClose />
                  </button>
                  <p style={{ color: "white", fontSize: "14px" }}>
                    User not in database
                  </p>
                </span>
              )
            )}
          </span>

          <Form.Item
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input
              placeholder={verfiedUser !== null ? verfiedUser?.name : "Name"}
            />
          </Form.Item>
          {verfiedUser !== null && userVehicles?.length !== 0 && (
            <Form.Item
              name=""
              rules={[{ required: true, message: "Vehicle rc no is required" }]}
            >
              <Input placeholder={"Vehicle registration number"} />
            </Form.Item>
          )}
          {verfiedUser !== null && userVehicles?.length !== 0 && (
            <Form.Item
              name="product_variant"
              rules={[{ required: true, message: "Product is required" }]}
            >
              <Select
                placeholder="Select vehicle"
                onChange={(value) =>
                  setDefaultCar(verfiedUser?.customer_id, value)
                }
              >
                {userVehicles !== null &&
                  userVehicles?.map((opt) => (
                    <Select.Option key={opt.id} value={opt?.id}>
                      {opt.maker_model}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input
              placeholder={verfiedUser !== null ? verfiedUser.email : "Email"}
            />
          </Form.Item>
          {verfiedUser !== null && userVehicles?.length !== 0 ? (
            <Link to={{ pathname: routes.MANUAL_FINAL, state: verfiedUser }}>
              <Button type="primary">Continue</Button>
            </Link>
          ) : userVehicles?.length === 0 ? (
            <Button
              type="primary"
              onClick={() => {
                setnewuUserDsiplay("flex");
                setmainDsiplay("none");
              }}
            >
              Continue
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Continue
            </Button>
          )}
        </Form>
      </div>
      <AddVehicle
        display={newUserDsiplay}
        userData={value}
        verfiedUser={verfiedUser}
      />
    </div>
  );
};

export default JobCardView;

// const Example=()=>{
//   const componentRef=useRef()
//   const handlePrint=useReactTpPrint({
//     content:()=>componentRef.current
//   })
// }
// return(
//   <div>
//     <UserData ref={componentRef}/>
//     <button onClick={handlePrint}>Print</button>
//   </div>
// )
