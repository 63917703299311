import "./index.scss";

import moment from "moment";
import { Link } from "react-router-dom";
import routes from "../../../../Common/routes";

const JobListCard = ({ jobcard, delivered }) => {
  console.log("Jobcard value", jobcard?.jobcardservices);
  return (
    <Link to={routes.VIEW_JOB_CARD(jobcard?.id)}>
      <article className="jobListCard jobListServiceCard">
        <header>
          <p>JOB ID {jobcard?.job_id}</p>
          <p>
            {moment(jobcard?.date_added, "YYYY-MM-DD HH:mm").format(
              "DD.MM.YYYY HH:mm"
            )}
          </p>
        </header>
        <div className="jobListCard-body">
          <div className="jobListCard-bodyWrap">
            <h3>{jobcard?.customer_vehicle_data?.vehicle_no}</h3>
          </div>
          <div className="jobListCard-bodyItems">
            {jobcard?.jobcardservices != null &&
            jobcard?.jobcardservices?.length <= 2
              ? jobcard?.jobcardservices
                  ?.map((item) => item?.service_name)
                  .join(", ")
              : jobcard?.jobcardservices
                  ?.map((item) => item?.service_name)
                  .slice(0, 2)
                  .join(", ") +
                ` ... + ${
                  isNaN(jobcard?.jobcardservices?.length - 2)
                    ? 0
                    : jobcard?.jobcardservices?.length - 2
                }`}
          </div>
        </div>
        {!delivered && jobcard?.services?.length > 0 && (
          <footer>
            <p
              className={
                jobcard?.jobcardservices.length > 0 &&
                jobcard?.jobcardservices
                  ?.map((service) => service?.employee !== null)
                  ?.every(Boolean)
                  ? `clr-danger`
                  : undefined
              }
            >
              Crew assigned
            </p>
            <p
              className={
                jobcard?.jobcardservices.length > 0 &&
                jobcard?.jobcardservices
                  ?.map((service) => service?.bay !== null)
                  ?.every(Boolean)
                  ? `clr-danger`
                  : undefined
              }
            >
              Bay assigned
            </p>
          </footer>
        )}
      </article>
    </Link>
  );
};

export default JobListCard;
