import React, { useEffect } from "react";
import "./stylesheet.scss";
import "./bootstrap.scss";
import logoKraft from "../../../../../Common/Assets/logoKraft.png";
import { useParams } from "react-router-dom";
import moment from "moment";
import JobAppBar from "../../../Common/JobAppBar";
import { useDashboardStore } from "../../../../../Stores/Dashboard";

const ReportList = () => {
  const { jobId } = useParams();

  const [{ listData }, { getReport }] = useDashboardStore();

  let subTotal = 0;
  let tax = 0;
  let total = 0;

  useEffect(() => {
    getReport(jobId);
  }, [getReport,jobId]);

  return (
    <div className="ReportInvoice">
      <JobAppBar onBack={() => window.history.back()} title="Reports" />
      <div class="container-fluid invoice-container">
        <header>
          <div class="row align-items-center">
            <div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
              <img id="logo" src={logoKraft} title="Koice" alt="Koice" />
            </div>
            <div class="col-sm-5 text-center text-sm-end">
              <h4 class="text-7 mb-0">Invoice</h4>
            </div>
          </div>
          <hr />
        </header>

        <main>
          <div class="row">
            <div class="col-sm-6">
              <strong>Date:</strong> {moment().format("DD/MM/YYYY")}
            </div>
            <div class="col-sm-6 text-sm-end"></div>
          </div>
          <hr />

          <div class="card">
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="card-header">
                    <tr>
                      <td class="col-3">
                        <strong>Service</strong>
                      </td>
                      <td class="col-2 text-center">
                        <strong>Job ID</strong>
                      </td>
                      <td class="col-1 text-center">
                        <strong>Vehicle</strong>
                      </td>
                      <td class="col-2 text-end">
                        <strong>Customer</strong>
                      </td>
                      <td class="col-2 text-end">
                        <strong>Total</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {listData?.map((res) =>
                      res?.services?.map((r,index) => {
                        subTotal = subTotal + r?.price;
                        tax = tax + r?.igst;
                        total = total + r?.total_price;
                        console.log(r, "daattaaaa");
                        return (
                          <tr key={r?.id}>
                            <td class="col-3">{r?.service_name}</td>
                            <td class="col-4 text-1">{r?.job_id}</td>
                            <td class="col-4 text-center">{r?.vehicle_no}</td>
                            <td class="col-4 text-1">{res?.customer}</td>
                            <td class="col-2 text-center">₹{r?.total_price}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                  <tfoot class="card-footer">
                    <tr>
                      <td colspan="4" class="text-end">
                        <strong>Sub Total:</strong>
                      </td>
                      <td class="text-end">₹{subTotal}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-end">
                        <strong>Tax:</strong>
                      </td>
                      <td class="text-end">₹{tax}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-end border-bottom-0">
                        <strong>Total:</strong>
                      </td>
                      <td class="text-end border-bottom-0">₹{total}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </main>
        <footer class="text-center mt-4">
          <p class="text-1">
            <strong>NOTE :</strong> This is computer generated receipt and does
            not require physical signature.
          </p>
          <div class="btn-group btn-group-sm d-print-none">
            <a
              href="javascript:window.print()"
              class="btn btn-light border text-black-50 shadow-none"
            >
              <i class="fa fa-print"></i> Print
            </a>
            {/* <a href="" class="btn btn-light border text-black-50 shadow-none">
              <i class="fa fa-download"></i> Download
            </a>{" "} */}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ReportList;
