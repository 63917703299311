import React from "react";

const UserData = ({ UserCarData }) => {
  return (
    <div className="jobCardView-left">
      <h3 className="jobCardView-title">User Details</h3>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Username </span>
        </p>
        <p>{UserCarData?.customer_details?.name}</p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Email </span>
        </p>
        <p>{UserCarData?.customer_details?.email}</p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Phone </span>
        </p>
        <p>{UserCarData?.customer_details?.phone}</p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Address </span>
        </p>
        <p>{UserCarData?.customer_details?.address}</p>
      </div>
      <h3 className="jobCardView-title">Car Details</h3>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Vehicle number </span>
        </p>
        <p>{UserCarData?.rc_number}</p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Vehicle </span>
        </p>
        <p>{UserCarData?.vehicle_name}</p>
      </div>
      <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Vehicle color </span>
        </p>
        <p>{UserCarData?.color}</p>
      </div>
      {/* <div
        className="jobCardView-detail"
        style={{
          display: "flex",
        }}
      >
        <p style={{ width: "120px" }}>
          <span>Engine Number</span>
        </p>
        <p>{UserCarData?.customer_vehicle_data?.engine_no}</p>
      </div> */}
    </div>
  );
};

export default UserData;
