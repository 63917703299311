export const convertObjectToFormData = (data) => {
  const formData = new FormData();

  for (let key in data) {
    formData.append(key, data[key]);
  }

  return formData;
};

export const convertArrayToFormData = (data) => {
  const formData = new FormData();

  formData.append("formdata", JSON.stringify(data));

  return formData;
};
