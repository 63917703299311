import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";
import config from "../../../common/config";

const Store = createStore({
  name: `automoto_store_${config.ENV}_profile`,
  initialState,
  actions,
});

export const useProfileStore = createHook(Store, {
  selector: (state) => state,
});
