import { Form } from "antd";
import { useEffect } from "react";
import { useBayStore } from "../../../Stores/Bay";
import BayForm from "../Form";

const BayEdit = () => {
  const [{ editModal, currentBay }, actions] = useBayStore();
  const [editForm] = Form.useForm();

  useEffect(() => {
    editForm.setFieldsValue(currentBay);
  }, [editModal, editForm, currentBay]);

  const handleSubmit = (values) => {
    console.log(values);
    actions.handleUpdateBay(values);
  };

  return (
    <>
      <BayForm form={editForm} onFinish={handleSubmit} />
    </>
  );
};

export default BayEdit;
