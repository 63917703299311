import { useBayStore } from "../../../Stores/Bay";
import BayForm from "../Form";
import { Form } from "antd";



const BayCreate = () => {
  const [, { handleCreateBay }] = useBayStore();
  const [form] = Form.useForm();
  const handleSubmit = (values,form) => {
    handleCreateBay(values);
    form.resetFields()
  };
  return (
    <>
      <BayForm form={form} onFinish={handleSubmit} />
    </>
  );
};

export default BayCreate;
