import React from "react";
import { Table, Space, Select, Tooltip } from "antd";
import { useJobcardStore } from "../../../../../Stores/Jobcard";
import { InlineInputEdit } from "react-inline-input-edit";
import { Modal, Button } from "antd";
import { useState, useEffect } from "react";
import { useInventoryStore } from "../../../../../Stores/Inventory";
import Loader from "../../../../../Common/UI/Loader";
import SparesTable from "./SparesTable";

const TableData = ({ cart, user }) => {
  const [
    { visibleSpare, render },
    { setVisibleSpare, deleteCart, editSingleService, addSpares },
  ] = useJobcardStore();
  const [{ inventoryList }, { handleGetInventoryList }] = useInventoryStore();

  useEffect(() => {
    handleGetInventoryList();
  }, [handleGetInventoryList, render]);

  const [service, setservice] = useState(null);
  const _handleFocus = (text) => {
    console.log("Focused with text: " + text);
  };
  const { Option } = Select;

  const showModal = (val) => {
    setVisibleSpare(true);
    setservice(val);
  };

  const handleOk = () => {
    setVisibleSpare(false);
  };

  const handleCancel = () => {
    setVisibleSpare(false);
  };
  console.log("cart data", cart);
  const columns = [
    {
      title: "Item",
      dataIndex: "service_name",
      key: "service_name",
      render: (val, record) => (
        <Tooltip
          placement="topLeft"
          title={"Click here to add and edit spares"}
        >
          <p style={{ cursor: "pointer" }} onClick={() => showModal(record)}>
            {val}
          </p>
        </Tooltip>
      ),
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price, record) => (
        <InlineInputEdit
          text={String(price)}
          inputWidth="100px"
          inputHeight="25px"
          inputMaxLength={50}
          labelFontWeight="bold"
          inputFontWeight="bold"
          onFocus={_handleFocus}
          onFocusOut={(text) =>
            editSingleService(text, record.service, user.id,"price")
          }
        />
      ),
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      render: (gst, record) => (
        <InlineInputEdit
          text={String(gst)}
          inputWidth="100px"
          inputHeight="25px"
          inputMaxLength={50}
          labelFontWeight="bold"
          inputFontWeight="bold"
          onFocus={_handleFocus}
          onFocusOut={(text) =>
            editSingleService(text, record.service, user.id,"gst")
          }
        />
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            style={{ color: "#f05c2d" }}
            onClick={() => deleteCart(record?.id, user?.id)}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={cart} />{" "}
      <Modal
        title={service?.service_name}
        visible={visibleSpare}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Select
          style={{ width: "100%" }}
          showSearch
          placeholder="Select a product"
          optionFilterProp="children"
          onChange={(value, price) => addSpares(price?.key, value, service?.id)}
          filterOption={(input, option) =>
            option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {inventoryList !== null ? (
            inventoryList?.map((item, key) => (
              <Option key={item?.id} value={item?.price}>
                {item?.name}
              </Option>
            ))
          ) : (
            <Loader tip="Loading products" />
          )}
        </Select>
        <div style={{ paddingTop: "34px" }} id="cart_table">
          <SparesTable
            cart={service?.spares}
            user={user}
            serviceId={service?.id}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TableData;
