import { message } from "antd";
import { logError } from "../../../common/utils";
import {
  createBay,
  deleteBay,
  getBayList,
  updateBay,
} from "../../../infrastructure/bay";
import routes from "../../Common/routes";
import { convertObjectToFormData } from "../../Common/Utils/convertToFormData";
import initialState from "./initialState";

const actions = {
  setLoadingCurrentBay:
    (loadingCurrentBay) =>
    ({ setState }) => {
      setState({ loadingCurrentBay });
    },

  setLoadingBayList:
    (loadingBayList) =>
    ({ setState }) => {
      setState({ loadingBayList });
    },

  setBayList:
    (bayList) =>
    ({ setState }) => {
      setState({ bayList });
    },

  setCurrentBay:
    (currentBay) =>
    ({ setState }) => {
      setState({ currentBay });
    },

  setIsSubmitting:
    (isSubmitting) =>
    ({ setState }) => {
      setState({ isSubmitting });
    },

  setAddModal:
    (addModal) =>
    ({ setState }) => {
      setState({ addModal });
    },

  setEditModal:
    (editModal) =>
    ({ setState }) => {
      setState({ editModal });
    },

  handleCreateBay:
    (values) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsSubmitting(true));
      try {
        await createBay(convertObjectToFormData(values));
        message.success("New Bay created !");
        dispatch(actions.setAddModal(false));
        const bayList = await getBayList();
        dispatch(actions.setBayList(bayList?.data));
      } catch (error) {
        logError(error, "handleCreateBay()");
        message.error("Error in creating bay");
      } finally {
        dispatch(actions.setIsSubmitting(false));
      }
    },

  handleUpdateBay:
    (values) =>
    async ({ getState, dispatch }) => {
      dispatch(actions.setIsSubmitting(true));
      try {
        const { id } = getState().currentBay;
        await updateBay(id, convertObjectToFormData(values));
        message.success("Bay updated !");
        dispatch(actions.setEditModal(false));
        dispatch(actions.handleGetBayData(id));
      } catch (error) {
        logError(error, "handleUpdateBay()");
        message.error("Error in updating bay");
      } finally {
        dispatch(actions.setIsSubmitting(false));
      }
    },

  handleDeleteBay:
    (history) =>
    async ({ getState }) => {
      try {
        const { id } = getState().currentBay;
        await deleteBay(id);
        message.success("Bay deleted !");
        history.push(routes.LIST_BAY);
      } catch (error) {
        logError(error, "handleDeleteBay()");
        message.error("Error in deleteing bay");
      }
    },

  handleGetBayList:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingBayList(true));
      try {
        const bayList = await getBayList();
        dispatch(actions.setBayList(bayList?.data));
      } catch (error) {
        logError(error, "getBayList()");
        message.error("Error in fetching bays");
      } finally {
        dispatch(actions.setLoadingBayList(false));
      }
    },

  handleGetBayData:
    (id, callback) =>
    async ({ getState, dispatch }) => {
      dispatch(actions.setLoadingCurrentBay(true));
      try {
        const data = await getBayList();
        const bayList = data.data;
        const bayData = bayList.find((item) => item?.id === id);
        dispatch(actions.setCurrentBay(bayData));
        callback && callback();
      } catch (error) {
        logError(error, "handleGetBayData()");
      } finally {
        dispatch(actions.setLoadingCurrentBay(false));
      }
    },

  resetStore:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

export default actions;
