import { get, patch, post } from "../common/remote";

export const createBay = (data) => {
  return post(`bays/add-bay/`, data);
};

export const getBayList = () => {
  return get(`bays/view-bays/`);
};

export const updateBay = (id, data) => {
  return patch(`bays/edit-bay/${id}`, data);
};

export const deleteBay = (id) => {
  return post(`bays/delete-bay/${id}`);
};
