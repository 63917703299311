import { Button, Form, Modal } from "antd";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import AssignModal from "./AssignModal";

const ServiceCard = ({ service, id, isCompleted }) => {
  const [
    { viewAssignModal,  },
    { setViewAssignModal,  },
  ] = useJobcardStore();
  const [bayEmpForm] = Form.useForm();
  const [productForm] = Form.useForm();

  const jobcardStatus = {
    pending: { label: "Waiting to Accept", color: "clr-warn" },
    accepted: { label: "Collected by employees", color: "clr-warn" },
    ongoing: { label: "Ongoing", color: "clr-msg" },
    completed: { label: "Completed", color: "clr-msg" },
    delivered: { label: "Delivered", color: "clr-msg" },
    rejected: { label: "Declined", color: "clr-danger" },
  };

  return (
    <div className="jobCardView-job">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>{jobcardStatus[service?.status]?.label}</p>
        <p>JOB ID {id}</p>
      </div>
      <h3>{service?.service_name}</h3>
      {isCompleted ? (
        <Button
          type="primary"
          style={{ width: "100%", marginTop: 15 }}
          className="jobCard-btn"
          disabled
        >
          Completed
        </Button>
      ) : (
        <>
          <Button
            onClick={() => setViewAssignModal(service?.id)}
            type="primary"
            className="jobCard-btn"
            style={{ width: "100%", marginTop: 15 }}
            // disabled={service?.status !== "pending"}
          >
            {service?.employee !== null && service?.bay !== null && "Re-"}Assign
            Bay and Employee
          </Button>

          <Modal
            centered
            visible={viewAssignModal === service?.id}
            title="Assign Bay & Employee"
            footer={null}
            onCancel={() => {
              bayEmpForm.resetFields();
              setViewAssignModal(null);
            }}
          >
            <AssignModal form={bayEmpForm} service={service} />
          </Modal>
        </>
      )}
      <p style={{ paddingTop: "8px" }}>
        {" "}
        Assigned Employee&nbsp; - &nbsp;
        {service?.employee !== null &&
          service?.bay !== null &&
          service?.employee_name}
      </p>
      <p style={{ paddingTop: "8px" }}>
        {" "}
        Assigned Bay&nbsp; - &nbsp;
        {service?.employee !== null &&
          service?.bay !== null &&
          service?.bay_name}
      </p>
    </div>
  );
};

export default ServiceCard;
