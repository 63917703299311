import "./index.scss";
import { useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { useInventoryStore } from "../../../Stores/Inventory";
import Loader from "../../../Common/UI/Loader";
import { useEffect } from "react";
import config from "../../../../common/config";
import DetailCard from "../../../Common/UI/DetailCard";

const InventoryView = ({ match }) => {
  const history = useHistory();
  const [
    { loadingInventory, inventory },
    { handleGetInventory, deleteInventory },
  ] = useInventoryStore();

  useEffect(() => {
    handleGetInventory(match?.params?.inventoryId);
  }, [handleGetInventory, match]);
  console.log(inventory, "inventory data now");

  return (
    <div className="inventoryViewContainer">
      <AppBar onBack={() => history.push(routes.LIST_INVENTORY)}>
        <div className="autoAppbar-title">
          <h3>{loadingInventory ? "Loading" : inventory?.product}</h3>
        </div>
      </AppBar>
      {loadingInventory ? (
        <Loader tip="Loading inventory..." />
      ) : (
        <div className="inventoryView">
          <DetailCard
            type="box"
            img={config.BASE_API + inventory?.image}
            name={inventory?.name}
            desc={inventory?.discription}
            details={{
              Product: inventory?.name,
              Price: inventory?.price,
              Rcp: inventory?.rcp,
              Category: inventory?.category,
            }}
            id={inventory?.id}
          />
          {/* <div>
            <button className="inventoryView-orderBtn">Order Item</button>
          </div> */}
          <button
            style={{
              backgroundColor: "tranparent",
              border: "none",
            }}
            className="delete_product"
            onClick={() => deleteInventory(inventory?.id)}
          >
            Delete
          </button>
        </div>
      )}
      {/* <Modal
        centered
        visible={viewAddStockModal}
        onCancel={() => setViewAddStockModal(false)}
        footer={null}
        title="Add Stock"
      >
        <AddStock
          id={match?.params?.inventoryId}
          varient={inventory.product_variant}
        />
      </Modal> */}
    </div>
  );
};

export default InventoryView;
