import "./index.scss";

import { Button, Form, Input } from "antd";
import { useBayStore } from "../../../Stores/Bay";

const BayForm = ({ onFinish, form }) => {
  const [{ isSubmitting }] = useBayStore();
  return (
    <Form
      className="bayForm"
      layout="vertical"
      scrollToFirstError
      requiredMark={false}
      form={form}
      onFinish={(values)=>onFinish(values,form)}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input />
      </Form.Item>
      <br />
      <Form.Item>
        <Button
          type="primary"
          style={{ width: "100%" }}
          loading={isSubmitting}
          htmlType="submit"
        >
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BayForm;
