import React from "react";
import { RingProgress } from "@ant-design/plots";

const index = (props) => {
  const config = {
    height: 100,
    width: 100,
    autoFit: false,
    percent: props.percent/100,
    color: ["#FF6F00", "#202020"],
  };
  return <RingProgress {...config} />;
};

export default index;
