import React, { useEffect } from "react";
import { Table, Space } from "antd";
import { useJobcardStore } from "../../../../../Stores/Jobcard";
import { InlineInputEdit } from "react-inline-input-edit";

const SparesTable = ({ cart, user, serviceId }) => {
  const [{ render }, { deleteCartSpare, editSingleSparePrice }] =
    useJobcardStore();
  const _handleFocus = (text) => {
    console.log("Focused with text: " + text);
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "spare_name",
      key: "spare_name",
      render: (val) => <p>{val}</p>,
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      render: (price, record) => (
        <InlineInputEdit
          text={String(price)}
          inputWidth="100px"
          inputHeight="25px"
          inputMaxLength={50}
          onFocus={_handleFocus}
          onFocusOut={(text) =>
            editSingleSparePrice(record?.spare, serviceId, text, "qty")
          }
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price, record) => (
        <InlineInputEdit
          text={String(price)}
          inputWidth="100px"
          inputHeight="25px"
          inputMaxLength={50}
          onFocus={_handleFocus}
          onFocusOut={(text) =>
            editSingleSparePrice(record?.spare, serviceId, text, "price")
          }
        />
      ),
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      render: (price, record) => (
        <InlineInputEdit
          text={String(price)}
          inputWidth="100px"
          inputHeight="25px"
          inputMaxLength={50}
          onFocus={_handleFocus}
          onFocusOut={(text) =>
            editSingleSparePrice(record?.spare, serviceId, text, "gst")
          }
        />
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            style={{ color: "#f05c2d" }}
            onClick={() => deleteCartSpare(record?.id, user?.id)}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  return <Table columns={columns} dataSource={cart} />;
};

export default SparesTable;
