import { get, post, del } from "../common/remote/base_api";

export const listJobcards = () => {
  return get(`stores/as-store/jobs-list-view/`);
};

export const listReport = (id) => {
  return get(`reports/store-jobwise-report/${id}/`);
};
export const getUserCarData = (id) => {
  return get(`stores/as-jobcard/customer-vehicle-details/${id}/`);
};
export const postCarImage = (id, data) => {
  return post(`stores/as-jobcard/customer-vehicle-view/add/${id}/`, data);
};
export const listJobcardsByStatus = (status) => {
  return get(`jobcards/list-status/?status=${status}`);
};

export const listAvailableEmployee = () => {
  return get(`jobcards/list-all-employee/`);
};

export const addProductToJobcard = (id, data) => {
  return post(`jobcards/assign-products-service/${id}/`, data);
};

export const viewJobcard = (id) => {
  return get(`stores/as-jobcard/single-view/${id}`);
};

export const acceptJobcard = (id) => {
  return post(`jobcards/accepted/${id}`);
};

export const assignJobcard = (id, data) => {
  return post(`jobcards/select/assign-items/${id}/`, data);
};

export const declineJobcard = (id) => {
  return get(`jobcards/rejected/${id}`);
};

export const deliverJobcard = (id) => {
  return post(`jobcards/delivered/${id}/`);
};
export const userVerify = (params) => {
  return post(`users/verify-customer/`, params);
};
export const createUser = (params) => {
  return post(`users/manual-create/customer/`, params);
};
export const userVehicles = (params) => {
  return get(`vehicles/manual-jobcard/vehicle-list/${params}`);
};
export const setDefaultCar = (customerID, params) => {
  return get(`vehicles/manual-jobcard/set-default/${customerID}/`, params);
};
export const userVehicleAdd = (rc, user) => {
  console.log(rc);
  return post(
    `vehicles/manual-add-cutomer-vehicle/${user}/?register_number=${rc}`
  );
};
export const userBrandAdd = (car, user, rc) => {
  return post(
    `/vehicles/manual-add-cutomer-vehicle-varient/${user}/?id=${car}&register_number=${rc}`
  );
};
export const addTocart = (id, params) => {
  return post(`stores/asstore/create-jobcard-service/${id}/`, params);
};
export const addSpareToCart = (id, params) => {
  return post(`stores/as-jobcard/create-spare/${id}/`, params);
};

export const deleteFromCart = (params) => {
  return post(`stores/as-jobcard/service-delete/${params}/`);
};
export const deleteFromCartSpare = (params) => {
  return post(`stores/as-jobcard/spare-delete/${params}/`);
};
export const placeOrderStart = (id) => {
  return post(`orders/manual-jobcard/ordering/${id}/`);
};

export const createManualJob = (id) => {
  return post(`orders/manual-job-card/place-order/${id}/`);
};
export const confirmData = (id) => {
  return post(`stores/asstore/create-job/?vehicle_id=${id}`);
};
export const viewJobCard = (id) => {
  return get(`stores/as-jobcard/view/${id}/`);
};
export const serviceStatus = (id, val) => {
  return post(`jobcards/select/status-change/${id}/?status=${val}`);
};
export const setAdvance = (id, val) => {
  return post(`stores/asstore/add-advance/${id}/`, val);
};
export const setRemarkNew = (id, val) => {
  return post(`stores/as-jobcard/add-comment/${id}/`, val);
};
export const updateRemarkNew = (id, val) => {
  return post(`stores/as-jobcard/update-comment/${id}/`, val);
};
export const deletRemark = (id) => {
  return post(`stores/as-jobcard/delete-comment/${id}/`);
};
export const deletRemarkImage = (id, params) => {
  return post(`stores/as-jobcard/customer-vehicle-image/delete/${id}/`, params);
};
export const floorManagerAssign = (id, params) => {
  return post(`stores/asstore/job/add-manager/${id}/`, params);
};
export const getServiceComb = (id, service) => {
  console.log("service values ", service);
  return get(
    `stores/manual-jobcard/customer-service-combination-view/${id}/?service=${service}`
  );
};
