import { useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import { Table, Menu } from "antd";
import { useDashboardStore } from "../../../Stores/Dashboard";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { downloadCSV } from "../../Reports/View/downloadAsCSV";
import { useState } from "react";

const InventoryView = ({ match }) => {
  const history = useHistory();
  const [{ listData }, { getReport }] = useDashboardStore();
  const [data, setdata] = useState(null);

  console.log(listData, "List data");
  useEffect(() => {
    getReport();
  }, [getReport]);

  useEffect(() => {
    var arr = [];
    listData &&
      listData?.forEach((element) => {
        let array1 = [];
        element.services.forEach((r) => {
          // arr.push({ service: r.service_name });
          array1.push({ service_name: r.service_name });
        });
        arr.push({ service: array1 });
      });
    setdata(arr);
    console.log(arr, "array valuesss");
  }, [listData]);

  const columns = [
    {
      title: "Vehicle",
      dataIndex: "vehicle_no",
      key: "vehicle_no",
      render: (text) => text,
    },
    {
      title: "Job Amount",
      dataIndex: "total_job_amount",
      key: "total_job_amount",
    },

    {
      title: "Service ",
      dataIndex: "services",
      key: "services",
      render: (text) => (
        <ul style={{ listStyleType: "none" }}>
          {text?.map((res) => (
            <li>{res?.service_name}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Service price ",
      dataIndex: "services",
      key: "services",
      render: (text) => (
        <ul style={{ listStyleType: "none" }}>
          {text?.map((res) => (
            <li>{res?.total_price}</li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <div className="inventoryViewContainer">
      <AppBar
        onBack={() => history.push(routes.HOME)}
        dropDownMenu={
          <Menu>
            <span onClick={() => downloadCSV(data, "ServiceList")}>
              <Menu.Item key="add">Download</Menu.Item>
            </span>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Daily report</h3>
        </div>
      </AppBar>
      <div id="daily_report_table">
        <Table columns={columns} dataSource={listData} />
      </div>
    </div>
  );
};

export default InventoryView;
