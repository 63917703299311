const initialState = {
  loadingInventoryList: true,
  inventoryList: null,
  loadingInventory: true,
  inventory: {},
  loadingVarients: true,
  varientList: null,
  viewAddModal: false,
  viewBulkAddModal: false,
  isAddingInventory: false,
  viewAddStockModal: false,
};

export default initialState;
