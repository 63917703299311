import { message } from "antd";
import { logError, msgError } from "../../../common/utils";
import {
  loginApi,
  resetUsernameAndPasswordApi,
} from "../../../infrastructure/auth";
import routes from "../../Common/routes";
import initialState from "./initialState";

const actions = {
  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  setUsername:
    (username) =>
    ({ setState }) => {
      setState({ username });
    },

  setIsAuthenticated:
    (isAuthenticated) =>
    ({ setState }) => {
      setState({ isAuthenticated });
    },

  logout: (history) => () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
   
    history.push(routes.LOGIN);
  },

  login:
    (values, history) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const token = localStorage.getItem("accessToken");
        console.log(token, "token value");
        const res = await loginApi(values);
        if (res?.StatusCode === 6000) {
          
          localStorage.setItem("accessToken",res?.data?.access);
          localStorage.setItem("refreshToken", res?.data?.refresh);
          localStorage.setItem("username", values?.username);
          history.push(routes.HOME);
        } else {
          message.warn(res?.data?.message);
        }
      } catch (error) {
        logError(error, "login()");
        msgError(error);
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  resetUsernameAndPassword:
    (values, history) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        if (values?.password === values?.cpassword) {
          delete values["cpassword"];
          const res = await resetUsernameAndPasswordApi(values);
          if (res?.StatusCode === 6000) {
            message.success("Username & Password updated");
            history.push(routes.HOME);
          } else {
            message.error("Error in updating username and password");
          }
        }
      } catch (error) {
        logError(error, "login");
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  authenticate:
    (history) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoading(true));
      const accessToken = await localStorage.getItem("accessToken");
      if (accessToken !== null) {
        dispatch(actions.setLoading(false));
      } else {
        message.error("Login to continue");
        dispatch(actions.setLoading(false));
        history.push(routes.LOGIN);
      }
    },

  resetStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

export default actions;
