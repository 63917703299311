import "./index.scss";
import "../index.scss";
import moment from "moment";
import JobAppBar from "../Common/JobAppBar";
import ServiceCard from "./Common/ServiceCard";
import JobDetails from "./Common/JobDetails";
import JobFooter from "./Common/JobFooter";
import { useEffect } from "react";
import { useJobcardStore } from "../../../Stores/Jobcard";
import Loader from "../../../Common/UI/Loader";
import { useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import JobInvoice from "./Common/JobInvoice";
import { Button } from "antd";
import Remarks from "../ManualCard/Remarks/Index";

const JobCardView = ({ match }) => {
  const [
    { loadingJobcard, jobcard },
    {
      handleGetJobcard,
      editSingleSparePrice,
      editSingleService,
      setServiceStatus,
      getUserCarData,
    },
  ] = useJobcardStore();
  const history = useHistory();

  const completedStatus = [
    "collected-by-employee",
    "ongoing",
    "completed-by-employee",
    "delivered",
  ];

  useEffect(() => {
    handleGetJobcard(match.params.jobId);
  }, [match, handleGetJobcard, editSingleService, setServiceStatus]);

  useEffect(() => {
    console.log(jobcard, "jobcard value");
    if (jobcard?.customer_details?.id !== undefined) {
      getUserCarData(jobcard?.customer_details?.id);
    }
  }, [jobcard]);

  return (
    <div className="jobCardViewContainer">
      <JobAppBar
        onBack={() => history.push(routes.LIST_JOB_CARD)}
        title="Jobcard"
      />
      {loadingJobcard ? (
        <Loader tip="Loading job card" />
      ) : (
        <div className="jobCardView">
          <div className="show-report-con">
            <Button
              type="primary"
              style={{ width: "100%", margin: "15px 0" }}
              className="jobCard-btn"
              onClick={() =>
                history.push(routes.VIEW_SINGLE_REPORT(jobcard?.id))
              }
            >
              View Invoice
            </Button>
            <Button
              type="primary"
              style={{ width: "100%", margin: "15px 0" }}
              className="jobCard-btn"
              onClick={() =>
                history.push(routes.VIEW_SINGLE_REPORT_REMARK(jobcard?.id))
              }
            >
              View Jobcard
            </Button>
          </div>
          <h3 className="jobCardView-title">Service Details</h3>
          <div className="jobCardView-header">
            <span>
              <h3>JOB ID {jobcard?.job_id}</h3>
              <h3>Floor Manager: &nbsp;&nbsp;{jobcard?.floor_manager}</h3>
            </span>
            <p>
              <span>Job created at </span>
              <br />
              {moment(jobcard?.date_added, "YYYY-MM-DD HH:mm").format(
                "DD.MM.YYYY HH:mm"
              )}
            </p>
          </div>

          <div className="jobCardView-jobs">
            {jobcard?.jobcardservices?.map((service, index) => (
              <ServiceCard
                key={service?.id}
                id={`${jobcard?.job_id}_${index}`}
                service={service}
                isCompleted={completedStatus.includes(jobcard?.status)}
              />
            ))}
            <Remarks
              id={jobcard?.customer_details?.id}
              status={jobcard?.status === "rejected" ? false : true}
              imageVal={false}
            />
          </div>
          <JobDetails jobcard={jobcard} />
          <JobInvoice
            jobcard={jobcard}
            editService={editSingleService}
            id={match.params.jobId}
            rerender={handleGetJobcard}
            editProduct={editSingleSparePrice}
          />
          {/* <JobProducts products={jobcard?.products} /> */}
          <JobFooter
            jobcard={jobcard}
            changeStatus={setServiceStatus}
            rerender={handleGetJobcard}
            id={match.params.jobId}
          />
        </div>
      )}
    </div>
  );
};

export default JobCardView;
