import { get, patch, post } from "../common/remote";

export const getEmployeeList = () => {
  return get("/employee/employees-view/");
};
export const listAttendance = (id) => {
  return get(`/employee/store-attendance-view/${id}/`);
};

export const editEmployee = (newData, id) => {
  return patch(`/employee/profile-update/employee/${id}`, newData);
};

export const deleteEmployee = (id) => {
  return post(`/employee/profile-delete/employee/${id}`);
};

export const addEmployee = (data) => {
  return post("/users/employee_profile_create/", data);
};
