import { Link } from "react-router-dom";
import routes from "../../../Common/routes";

const BayCard = ({ bay }) => {
  return (
    <Link to={routes.VIEW_BAY(bay?.id)}>
      <div className="bayListCard">
        <section className="bayListCard-left">
          <h3>{bay?.name}</h3>
          <p>Bay</p>
        </section>
        <section className="bayListCard-right">
          <p>{bay?.description}</p>
          <span>
            {isNaN(bay?.onGoingServices?.length)
              ? 0
              : bay?.onGoingServices?.length}{" "}
            ongoing services
          </span>
        </section>
      </div>
    </Link>
  );
};

export default BayCard;
