import "./index.scss";

import {  useHistory } from "react-router-dom";

import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import DetailCard from "../../../Common/UI/DetailCard";
import { useProfileStore } from "../../../Stores/Profile";
import { useEffect } from "react";

const ProfileView = () => {
  const history = useHistory();
  const [{ profile }, { getProdileData }] = useProfileStore();

  useEffect(() => {
    getProdileData();
  }, [getProdileData]);

  console.log(profile, "profile data");

  return (
    <div className="profileViewContainer">
      <AppBar
        onBack={() => history.push(routes.HOME)}
        // dropDownMenu={
        //   <Menu>
        //     <Link to={routes.EDIT_PROFILE}>
        //       <Menu.Item key="add">Edit Profile</Menu.Item>
        //     </Link>
        //   </Menu>
        // }
      >
        <div className="autoAppbar-title">
          <h3>Store Profile</h3>
        </div>
      </AppBar>
      <DetailCard
        img=""
        type="box"
        name={profile?.name}
        desc={profile?.store_id}
        details={{
          "Store Name": profile?.name,
          Username: profile?.username,
          Email: profile?.email,
          "Phone Number": profile?.phone,
          Address: profile?.address,
        }}
      />
    </div>
  );
};

export default ProfileView;
