import "../index.scss";

import { Empty, Menu, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { FeatherIcon } from "../../../Common/Assets";
import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import BayCard from "../Common/BayCard";
import BayCreate from "../Create";
import { useBayStore } from "../../../Stores/Bay";
import { useEffect } from "react";
import Loader from "../../../Common/UI/Loader";

const BayList = () => {
  const [
    { addModal, bayList, loadingBayList },
    { setAddModal, handleGetBayList },
  ] = useBayStore();
  const history = useHistory();

  useEffect(() => {
    handleGetBayList();
  }, [handleGetBayList]);

  return (
    <div className="bayListContainer">
      <AppBar
        onBack={() => history.push(routes.HOME)}
        dropDownMenu={
          <Menu>
            <Menu.Item key="add" onClick={() => setAddModal(true)}>
              Add
            </Menu.Item>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Bay Management</h3>
          <FeatherIcon icon="search" />
        </div>
      </AppBar>

      {loadingBayList ? (
        <Loader tip="Loading bays..." />
      ) : bayList.length > 0 ? (
        bayList.map((item) => <BayCard key={item.name} bay={item} />)
      ) : (
        <Empty />
      )}

      <Modal
        title="Create Bay"
        visible={addModal}
        onCancel={() => setAddModal(false)}
        centered
        footer={null}
      >
        <BayCreate />
      </Modal>
    </div>
  );
};

export default BayList;
