import "antd/dist/antd.css"; // antd css
import "./presentation/Common/Style/antd.css"; // antd override css
import "./presentation/Common/Style/styles.scss"; // global css
import "./index.css";
import "./bootstrap.scss"

import Router from "./presentation/Router";

const App = () => {
  return <Router />;
};

export default App;
