import React, { useEffect } from "react";
import "./stylesheet.scss";
import "./bootstrap.scss";
import logoKraft from "../../../../../Common/Assets/logoKraft.png";
import { useParams } from "react-router-dom";
import { useJobcardStore } from "../../../../../Stores/Jobcard";
import moment from "moment";
import JobAppBar from "../../../Common/JobAppBar";
import { useHistory } from "react-router-dom";
import routes from "../../../../../Common/routes";
import { useProfileStore } from "../../../../../Stores/Profile";

const ReportInvoice = () => {
  const { jobid } = useParams();

  var array = [];

  const [{  jobcard }, { handleGetJobcard }] = useJobcardStore();
  const [{ profile }, { getProdileData }] = useProfileStore();

  const history = useHistory();
  let subTotal = 0;
  let subTotalService = 0;
  let tax = 0;
  let total = 0;

  useEffect(() => {
    handleGetJobcard(jobid);
    getProdileData();
  }, [handleGetJobcard, jobid, getProdileData]);

  jobcard?.jobcardservices?.forEach((element) => {
    element?.spares?.forEach((res) => {
      array.push(res);
    });
  });


  return (
    <div className="ReportInvoice">
      <JobAppBar
        onBack={() => history.push(routes.VIEW_JOB_CARD(jobid))}
        title="Reports"
      />
      <div
        class="container-fluid invoice-container"
        style={{ paddingTop: "12px" }}
      >
        <header>
          <div class="row align-items-center">
            <div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
              <img style={{width:"120px"}} id="logo" src={logoKraft} title="Koice" alt="Koice" />
            </div>
            <div class="col-sm-5 text-center text-sm-end">
              <h4 class="text-7 mb-0">Invoice</h4>
            </div>
          </div>
          <hr />
        </header>

        <main>
          <div class="row">
            <div class="col-sm-6">
              <strong>Date:</strong> {moment().format("DD/MM/YYYY")}
            </div>
            <div class="col-sm-6 text-sm-end">
              {" "}
              <strong>Invoice No:</strong> {jobcard?.job_id}
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6 text-sm-end order-sm-1">
              {" "}
              <strong>Pay To:</strong>
              <address>
                {profile?.name}
                <br />
                {profile?.address}
                <br />
                {profile?.username}
                <br />
                {profile?.email}
              </address>
            </div>
            <div class="col-sm-6 order-sm-0">
              {" "}
              <strong>Invoiced To:</strong>
              <address>
                {jobcard?.customer_details?.name}
                <br />
                {jobcard?.customer_details?.address}
                {jobcard?.customer_details?.street}
                <br />
                {jobcard?.customer_details?.phone}
                <br />
                {jobcard?.customer_details?.email}
                <br />
              </address>
              <address style={{ display: "flex", flexDirection: "column" }}>
                <strong>
                  Vehicle Number:&nbsp;
                  {jobcard?.customer_vehicle_data?.vehicle_no}
                </strong>
                <strong>
                  Vehicle Brand:&nbsp;
                  {jobcard?.customer_vehicle_data?.brand}
                </strong>
                <strong>
                  Vehicle Model:&nbsp;
                  {jobcard?.customer_vehicle_data?.model}
                </strong>
                <strong>
                  Vehicle Year:&nbsp;
                  {jobcard?.customer_vehicle_data?.year}
                </strong>
              </address>
            </div>
          </div>

          <div class="card">
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="card-header">
                    <tr>
                      <td class="col-6">
                        <strong>Service</strong>
                      </td>
                      <td class="col-2">
                        <strong>Job ID</strong>
                      </td>
                      {/* <td class="col-2 text-center">
                        <strong>Rate</strong>
                      </td> */}
                      <td class="col-1 text-center">
                        <strong>Tax</strong>
                      </td>

                      <td class="col-2 text-end">
                        <strong>Amount</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {jobcard?.jobcardservices?.map((r) => {
                      subTotalService = subTotalService + r?.price;
                      tax = tax + r?.price * (r?.gst / 100);
                      total = total + r?.total_price;

                      return (
                        <tr key={r?.id}>
                          <td class="col-3">{r?.service_name}</td>
                          <td class="col-4 text-1">{jobcard?.job_id}</td>
                          <td class="col-2 text-center">
                            GST&nbsp;{r?.gst}%<br />₹{r?.price * (r?.gst / 100)}
                          </td>
                          <td class="col-2 text-end">₹{r?.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot class="card-footer">
                    <tr>
                      <td colspan="2" class="text-end">
                        <strong>Sub Total:</strong>
                      </td>
                      <td colspan="2" class="text-end">
                        ₹{subTotalService}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <table class="table mb-0">
                  <thead class="card-header">
                    <tr>
                      <td class="col-6">
                        <strong>Product</strong>
                      </td>
                      <td class="col-1">
                        <strong>Quantity</strong>
                      </td>
                      {/* <td class="col-1 text-center">
                        <strong>Rate</strong>
                      </td> */}
                      <td class="col-1 text-center">
                        <strong>GST</strong>
                      </td>
                      <td class="col-2 text-end">
                        <strong>Amount</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {array?.map((r) => {
                      subTotal = subTotal + r?.price;
                      tax = tax + r?.price * (r?.gst / 100);
                      total = total + r?.total_price;

                      return (
                        <tr key={r?.id}>
                          <td class="col-3">{r?.spare_name}</td>
                          <td class="col-4 text-1">{r?.qty}</td>
                          <td class="col-2 text-center">
                            <td class="col-2 text-center">
                              GST&nbsp;{r?.gst}%<br />₹
                              {r?.price * (r?.gst / 100)}
                            </td>
                          </td>
                          <td class="col-2 text-end">₹{r?.total_price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot class="card-footer">
                    <tr>
                      <td colspan="2" class="text-end">
                        <strong>Sub Total:</strong>
                      </td>
                      <td colspan="2" class="text-end">
                        ₹{subTotal}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="text-end border-bottom-0">
                        <strong>Advance:</strong>
                      </td>
                      <td colspan="2" class="text-end border-bottom-0">
                        ₹{jobcard?.advance}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end border-bottom-0">
                        <strong>Total:</strong>
                      </td>
                      <td colspan="2" class="text-end border-bottom-0">
                        ₹{jobcard?.total_price - jobcard?.advance}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end border-bottom-0">
                        <strong>Tax:</strong>
                      </td>
                      <td colspan="2" class="text-end border-bottom-0">
                        ₹{tax}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end border-bottom-0">
                        <strong>Grand Total:</strong>
                      </td>
                      <td colspan="2" class="text-end border-bottom-0">
                        ₹{jobcard?.total_price}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </main>
        <footer class="text-center mt-4">
          <p class="text-1">
            <strong>NOTE :</strong> This is computer generated receipt and does
            not require physical signature.
          </p>
          <div class="btn-group btn-group-sm d-print-none">
            <a
              href="javascript:window.print()"
              class="btn btn-light border text-black-50 shadow-none"
            >
              <button class="fa fa-print" onClick={() => window.print()}>
                Print
              </button>
            </a>
            {/* <a href="" class="btn btn-light border text-black-50 shadow-none">
              <i class="fa fa-download"></i> Download
            </a>{" "} */}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ReportInvoice;
