import "./index.scss";

import { Menu, Modal } from "antd";
import { useHistory } from "react-router-dom";

import routes from "../../../Common/routes";
import AppBar from "../../../Common/UI/AppBar";
import BayDelete from "../Delete";
import { useBayStore } from "../../../Stores/Bay";
import BayEdit from "../Edit";
import { useEffect } from "react";
import Loader from "../../../Common/UI/Loader";

const BayView = ({ match }) => {
  const [{ editModal, currentBay, loadingCurrentBay }, actions] = useBayStore();
  const history = useHistory();

  useEffect(() => {
    console.log("65465kjjvbghj", match?.params?.bayId);
    actions.handleGetBayData(match?.params?.bayId);
    return () => {
      actions.setCurrentBay(null);
    };
  }, [match, actions]);

  return (
    <div className="bayViewContainer">
      <AppBar
        onBack={() => history.push(routes.LIST_BAY)}
        dropDownMenu={
          <Menu>
            <Menu.Item key="edit" onClick={() => actions.setEditModal(true)}>
              Edit
            </Menu.Item>
            <Menu.Item
              key="delete"
              onClick={() => BayDelete(() => actions.handleDeleteBay(history))}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
      >
        <div className="autoAppbar-title">
          <h3>Bay Management</h3>
        </div>
      </AppBar>
      {loadingCurrentBay ? (
        <Loader tip="Loading Bay..." />
      ) : (
        <>
          <Modal
            title="Edit Bay"
            visible={editModal}
            onCancel={() => actions.setEditModal(false)}
            centered
            // okText="Save"
            footer={null}
          >
            <BayEdit />
          </Modal>
          <div className="bayView">
            <div className="bayView-dtl">
              <p>Bay</p>
              <h1>{currentBay?.name}</h1>
            </div>
            {/* <div className="bayView-dtl">
              <p>Description</p>
              <h4>{currentBay?.description}</h4>
            </div> */}
            <div className="bayView-dtl">
              <p>Ongoing Services</p>
              {currentBay?.onGoingServices?.map((service) => (
                <h4>{service}</h4>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BayView;
