import "../Login/index.scss";

import { Button, Form, Input } from "antd";
import { useHistory } from "react-router-dom";

import { logoWithTitle } from "../../Common/Assets";
import { useAuthStore } from "../../Stores/Auth";
import { useEffect } from "react";
import { getStorageItem } from "../../../infrastructure/common/local";

const AuthReset = () => {
  const history = useHistory();
  const [{ loading }, { resetUsernameAndPassword }] = useAuthStore();
  const [authForm] = Form.useForm();

  useEffect(() => {
    const setFieldsValue = async () => {
      authForm.setFieldsValue({ username: await localStorage.getItem("username") });
    };
    setFieldsValue();
  }, [authForm]);

  return (
    <div className="loginContainer">
      <div className="loginBanner">
        <img src={logoWithTitle} alt="logo" />
      </div>
      <div className="loginSpace"></div>
      <div className="loginPortal">
        <Form
          form={authForm}
          layout="vertical"
          requiredMark={false}
          onFinish={(values) => resetUsernameAndPassword(values, history)}
        >
          <Form.Item
            label="New Username"
            name="username"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="cpassword"
            rules={[{ required: true, message: "Confirm password" }]}
            validateStatus={
              authForm.getFieldValue("password") ===
              authForm.getFieldValue("cpassword")
                ? "success"
                : "error"
            }
            help="Passwords didn't match"
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <br />
          <Form.Item>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              htmlType="submit"
            >
              Save & Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AuthReset;
