const JobDetails = ({ jobcard }) => {
  return (
    <>
      <br />

      <h3 className="jobCardView-title">Vehicle Details</h3>
      <div className="jobCardView-details">
        <img
          style={{ width: "140px", paddingTop: "24px", paddingBottom: "24px" }}
          src={jobcard?.customer_vehicle_data?.image}
        ></img>

        <div className="jobCardView-detail">
          <p>Vehicle Number</p>
          <p>{jobcard?.customer_vehicle_data?.vehicle_no}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Brand</p>
          <p>{jobcard?.customer_vehicle_data?.brand}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Model</p>
          <p>{jobcard?.customer_vehicle_data?.model}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Year</p>
          <p>{jobcard?.customer_vehicle_data?.year}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Engine Number</p>
          <p>{jobcard?.customer_vehicle_data?.engine_no}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Chasis Number</p>
          <p>{jobcard?.customer_vehicle_data?.chasis_no}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Fuel Type</p>
          <p>{jobcard?.customer_vehicle_data?.fuel_type}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Insurance Company</p>
          <p>
            {jobcard?.customer_vehicle_data?.insurance_company === ""
              ? "Not available"
              : jobcard?.customer_vehicle_data?.insurance_company}
          </p>
        </div>
        <div className="jobCardView-detail">
          <p>Insurance Upto</p>
          <p>{jobcard?.customer_vehicle_data?.insurance_upto}</p>
        </div>
      </div>

      <br />

      <h3 className="jobCardView-title">Customer Details</h3>
      <div className="jobCardView-details">
        <div className="jobCardView-detail">
          <p>Name</p>
          <p>{jobcard?.customer_details?.name}</p>
        </div>
        <div className="jobCardView-detail">
          <p>Contact</p>
          <p>
            {jobcard?.customer_details?.phone}
            <a
              href={`tel:${jobcard?.phone}`}
              style={{ color: "#FC5C2E", marginLeft: 10 }}
            >
              CALL
            </a>
          </p>
        </div>
        <div className="jobCardView-detail">
          <p>Email</p>
          <p>{jobcard?.customer_details?.email}</p>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
