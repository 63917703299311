import "./index.scss";

const ListCard = ({ header = [], body = [], footer = [], img }) => {
  return (
    <article className="autoListCard">
      {header?.length >= 1 && (
        <header>
          {header?.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </header>
      )}

      {body?.length >= 1 && (
        <div className="autoListCard-body">
          <div className="autoListCard-bodyLeft">
            {img && <img src={img} alt="autoListCard-img" />}
            <div>
              <h3>{body[0]}</h3>
              <p>{body[1]}</p>
            </div>
          </div>
          {body?.length > 2 &&
            body
              ?.slice(2, body?.length)
              ?.map((item, index) => <span key={index}>{item}</span>)}
        </div>
      )}

      {footer?.length >= 1 && (
        <footer>
          {footer?.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </footer>
      )}
    </article>
  );
};

export default ListCard;
