import "./index.scss";
import React from "react";
import { useEmployeeStore } from "../../../Stores/Employee";
import AppBar from "../../../Common/UI/AppBar";

const Feedback = (props) => {
  const [{ listData }] = useEmployeeStore();

  return (
    <div className="autoLogs">
      <AppBar onBack={() => window.history.back()}>
        <div className="autoAppbar-title">
          <h3>{listData[0]?.employee_name}</h3>
        </div>
      </AppBar>
      <div style={{ margin: "20px" }}>
        <h1>Punch In Location</h1>{" "}
        <iframe
          title="loc"
          width="100%"
          height="190"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src={`https://maps.google.com/maps?q=${
            listData[props.location.param1]?.punch_in_latitude
          },${
            listData[props.location.param1]?.punch_in_longitude
          }&hl=es&z=14&amp;&output=embed`}
        ></iframe>
        <h1>Punch Out Location</h1>{" "}
        <iframe
          title="locout"
          width="100%"
          height="190"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src={`https://maps.google.com/maps?q=${
            listData[props.location.param1]?.punch_out_latitude
          },${
            listData[props.location.param1]?.punch_out_longitude
          }&hl=es&z=14&amp;&output=embed`}
        ></iframe>
      </div>
    </div>
  );
};

export default Feedback;
