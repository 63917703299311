import "./index.scss";

import AppBar from "../../../Common/UI/AppBar";
import { useHistory } from "react-router";
import routes from "../../../Common/routes";
import { useEffect } from "react";
import { useServiceStore } from "../../../Stores/Services";
import Loader from "../../../Common/UI/Loader";
import ListCollection from "../../../Common/UI/ListCollection";
import { Empty } from "antd";
import { InlineInputEdit } from "react-inline-input-edit";

const ServicesList = () => {
  const _handleFocus = (text) => {
    console.log("Focused with text: " + text);
  };

  const history = useHistory();
  const [
    { serviceCategory, loading },
    { getServiceCategory, setPriceService },
  ] = useServiceStore();

  useEffect(() => {
    getServiceCategory();
  }, [getServiceCategory]);

  const InlineData = (value, fn) => {
    return (
      <InlineInputEdit
        text={String(value)}
        inputWidth="100px"
        inputHeight="25px"
        inputMaxLength={50}
        onFocus={_handleFocus}
        onFocusOut={fn}
      />
    );
  };


  if (loading) return <Loader tip="Getting service categories..." />;
  return (
    <div className="seviceClass">
      <AppBar onBack={() => history.push(routes.HOME)}>
        <div className="autoAppbar-title">
          <h3>Service Management</h3>
        </div>
      </AppBar>
      <ListCollection>
        <table style={{ width: "100%" }} id="list_services">
          <tr className="list_services_head">
            <th>Services</th>
            <th>Price</th>
            <th>CGST</th>
            <th>SGST</th>
            <th>IGST</th>
          </tr>
          {serviceCategory.length > 0 ? (
            serviceCategory?.map((item, key) => (
              <tr>
                <td>{item?.service_details?.name}</td>
                <td>
                  {item?.service_price === null
                    ? InlineData(0, (text) =>
                        setPriceService(text, "price", item?.id)
                      )
                    : InlineData(item?.service_price?.price, (text) =>
                        setPriceService(text, "price", item?.id)
                      )}
                </td>
                <td>
                  {item?.service_price === null
                    ? InlineData(0, (text) =>
                        setPriceService(text, "cgst", item?.id)
                      )
                    : InlineData(item?.service_price?.cgst, (text) =>
                        setPriceService(text, "cgst", item?.id)
                      )}
                </td>
                <td>
                  {item?.service_price === null
                    ? InlineData(0, (text) =>
                        setPriceService(text, "sgst", item?.id)
                      )
                    : InlineData(item?.service_price?.sgst, (text) =>
                        setPriceService(text, "sgst", item?.id)
                      )}
                </td>
                <td>
                  {item?.service_price === null
                    ? InlineData(0, (text) =>
                        setPriceService(text, "igst", item?.id)
                      )
                    : InlineData(item?.service_price?.igst, (text) =>
                        setPriceService(text, "igst", item?.id)
                      )}
                </td>
              </tr>
            ))
          ) : (
            <Empty />
          )}
        </table>
      </ListCollection>
    </div>
  );
};

export default ServicesList;
