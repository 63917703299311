import { Button, message, Modal } from "antd";
import { useJobcardStore } from "../../../../Stores/Jobcard";
import { getJobcardStatus } from "./getJobcardStatus";
import { useHistory, Link } from "react-router-dom";
import { useEffect } from "react";
import routes from "../../../../Common/routes";

const JobFooter = ({ jobcard, changeStatus, rerender, id }) => {
  const [{ isUpdatingStatus }, { handleUpdateStatusJobcard }] =
    useJobcardStore();
  const history = useHistory();
  const customerAr = [];

  const jobcardStatus = {
    pending: { label: "Pending", color: "clr-warn" },
    accepted: { label: "Ongoing", color: "clr-warn" },
    inprogress: { label: "Ongoing", color: "clr-msg" },
    completed: { label: "Completed", color: "clr-msg" },
    delivered: { label: "Delivered", color: "clr-msg" },
    rejected: { label: "Declined", color: "clr-danger" },
  };

  useEffect(() => {
    var customer_id = jobcard?.customer_details?.id;
    customerAr.push({ customer_id: customer_id });
  }, [customerAr]);

  return (
    <footer className="jobCardView-footer">
      <div className="jobCardView-footerInfo">
        {jobcard?.status !== "rejected" && jobcard?.status !== "delivered" && (
          <Link
            to={{
              pathname: routes.MANUAL_FINAL,
              state: jobcard?.customer_details,
            }}
          >
            <p>Add or update data</p>
          </Link>
        )}
        <p
          className={
            jobcardStatus[getJobcardStatus(jobcard)]?.color
              ? jobcardStatus[getJobcardStatus(jobcard)]?.color
              : "clr-warn"
          }
        >
          <span>Status: </span>
          {jobcardStatus[getJobcardStatus()]?.label
            ? jobcardStatus[getJobcardStatus(jobcard)]?.label
            : "Unknown"}
        </p>
        {jobcard?.status !== "delivered" &&
          jobcard?.status !== "rejected" &&
          jobcard?.status !== "completed" && (
            <Button
              onClick={() =>
                Modal.confirm({
                  centered: true,
                  title: "Decline Job",
                  content: "Are you sure you want to decline the job ?",
                  okText: "Decline",
                  okButtonProps: { type: "danger" },
                  onOk: async () => {
                    await changeStatus(jobcard?.id, "rejected");
                    rerender(jobcard?.id);
                    message.success("Card declined");
                  },
                })
              }
            >
              Decline Job
            </Button>
          )}
      </div>

      <br />

      {console.log(`getJobcardStatus(jobcard)`, getJobcardStatus(jobcard))}
      {jobcard?.status === "pending" || jobcard?.status === "accepted" ? (
        <Button
          className="jobCard-btn"
          style={{ width: "100%", marginBottom: "24px" }}
          loading={isUpdatingStatus}
          onClick={async () => {
            await changeStatus(jobcard?.id, "inprogress");
            rerender(jobcard?.id);
            message.success("Card moved to ongoing");
          }}
        >
          Start working
        </Button>
      ) : jobcard?.status === "inprogress" ? (
        <Button
          className="jobCard-btn"
          style={{ width: "100%" }}
          loading={isUpdatingStatus}
          onClick={async () => {
            await changeStatus(jobcard?.id, "completed");
            rerender(jobcard?.id);
            message.success("Card moved to completed");
          }}
        >
          Complete
        </Button>
      ) : jobcard?.status === "completed" ? (
        <Button
          className="jobCard-btn"
          style={{ width: "100%" }}
          loading={isUpdatingStatus}
          onClick={async () => {
            await changeStatus(jobcard?.id, "delivered");
            rerender(jobcard?.id);
            message.success("Card moved to delivered");
          }}
        >
          Payment and Deliver
        </Button>
      ) : jobcard?.status === "delivered" ? (
        <Button
          disabled
          className="jobCard-btn"
          style={{ width: "100%" }}
          loading={isUpdatingStatus}
        >
          Delivered
        </Button>
      ) : jobcard?.status === "rejected" ? (
        <Button
          disabled
          className="jobCard-btn"
          style={{ width: "100%" }}
          loading={isUpdatingStatus}
        >
          Declined
        </Button>
      ) : (
        <></>
      )}
    </footer>
  );
};

export default JobFooter;
