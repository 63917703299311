import { Empty, Menu, Tabs, Row, Col } from "antd";
import JobAppBar from "../Common/JobAppBar";
import { useEffect, useState } from "react";
import { useJobcardStore } from "../../../Stores/Jobcard";
import Loader from "../../../Common/UI/Loader";
import JobListCard from "../Common/JobListCard";
import { Link, useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import { getJobcardStatus } from "../View/Common/getJobcardStatus";
import "./index.scss";

const { TabPane } = Tabs;

const jobcardStatus = {
  pending: { label: "Waiting to Accept", color: "clr-warn" },
  accepted: { label: "Ongoing", color: "clr-msg" },
  ongoing: { label: "Ongoing", color: "clr-msg" },
  completed: { label: "Completed", color: "clr-msg" },
  delivered: { label: "Delivered", color: "clr-msg" },
  rejected: { label: "Declined", color: "clr-danger" },
};

const JobCardList = () => {
  const [{ jobcardList, loadingCardList }, { handleGetAllJobcards }] =
    useJobcardStore();
  const [activeKey, setactiveKey] = useState("new");

  const history = useHistory();

  useEffect(() => {
    handleGetAllJobcards();
    const keyVal = sessionStorage.getItem("active");
    if (keyVal) {
      setactiveKey(keyVal);
    } else {
      setactiveKey("new");
    }
  }, [handleGetAllJobcards]);

  const callback = (key) => {
    sessionStorage.setItem("active", key);
    const keyVal = sessionStorage.getItem("active");
    console.log(keyVal, "defalt key");
    setactiveKey(keyVal);
  };
  return (
    <div className="jobCardListContainer">
      <JobAppBar
        onBack={() => history.push(routes.HOME)}
        title="JobCards"
        dropDownMenu={<Menu></Menu>}
      />
      <Link to={routes.MANUAL_CARD}>
        <button className="manual_jobcard">Create Job Card</button>
      </Link>
      {loadingCardList ? (
        <Loader tip="Loading jobcards" />
      ) : (
        <div className="jobCardList">
          <Tabs activeKey={activeKey} tabPosition="top" onChange={callback}>
            <TabPane tab="New" key="new" className="jobListCards">
              <Row gutter={16}>
                {jobcardList?.filter((job) =>
                  jobcardStatus[getJobcardStatus(job)]?.label.includes(
                    "Waiting to Accept"
                  )
                ).length > 0 ? (
                  jobcardList
                    ?.filter((job) =>
                      jobcardStatus[getJobcardStatus(job)]?.label.includes(
                        "Waiting to Accept"
                      )
                    )
                    ?.map((jobcard) => (
                      <Col span={12} xs={20} sm={20} md={12} lg={12} xl={12}>
                        <JobListCard
                          key={jobcard?.id}
                          jobcard={jobcard}
                          delivered={false}
                        />
                      </Col>
                    ))
                ) : (
                  <Empty />
                )}
              </Row>
            </TabPane>
            <TabPane tab="Ongoing" key="inprogress" className="jobListCards">
              <Row gutter={16}>
                {jobcardList?.filter(
                  (job) =>
                    job?.status === "inprogress" || job?.status === "accepted"
                ).length > 0 ? (
                  jobcardList
                    ?.filter(
                      (job) =>
                        job?.status === "inprogress" ||
                        job?.status === "accepted"
                    )
                    ?.map((jobcard) => (
                      <Col span={12} xs={20} sm={20} md={12} lg={12} xl={12}>
                        <JobListCard
                          key={jobcard?.id}
                          jobcard={jobcard}
                          delivered={false}
                        />
                      </Col>
                    ))
                ) : (
                  <Empty />
                )}
              </Row>
            </TabPane>
            <TabPane tab="Completed" key="completed" className="jobListCards">
              <Row gutter={16}>
                {jobcardList?.filter((job) =>
                  jobcardStatus[getJobcardStatus(job)]?.label.includes(
                    "Completed"
                  )
                ).length > 0 ? (
                  jobcardList
                    ?.filter((job) =>
                      jobcardStatus[getJobcardStatus(job)]?.label.includes(
                        "Completed"
                      )
                    )
                    ?.map((jobcard) => (
                      <Col span={12} xs={20} sm={20} md={12} lg={8} xl={8}>
                        <JobListCard
                          key={jobcard?.id}
                          jobcard={jobcard}
                          delivered={false}
                        />
                      </Col>
                    ))
                ) : (
                  <Empty />
                )}
              </Row>
            </TabPane>
            <TabPane tab="Delivered" key="delivered" className="jobListCards">
              <Row gutter={16}>
                {jobcardList?.filter((job) =>
                  jobcardStatus[getJobcardStatus(job)]?.label.includes(
                    "Delivered"
                  )
                ).length > 0 ? (
                  jobcardList
                    ?.filter((job) =>
                      jobcardStatus[getJobcardStatus(job)]?.label.includes(
                        "Delivered"
                      )
                    )
                    ?.map((jobcard) => (
                      <Col span={12} xs={20} sm={20} md={12} lg={8} xl={8}>
                        <JobListCard
                          key={jobcard?.id}
                          jobcard={jobcard}
                          delivered={true}
                        />
                      </Col>
                    ))
                ) : (
                  <Empty />
                )}
              </Row>
            </TabPane>
            <TabPane tab="Rejected" key="rejected" className="jobListCards">
              <Row gutter={16}>
                {jobcardList?.filter((job) => job?.status === "rejected")
                  .length > 0 ? (
                  jobcardList
                    ?.filter((job) => job?.status === "rejected")
                    ?.map((jobcard) => (
                      <Col span={12} xs={20} sm={20} md={12} lg={8} xl={8}>
                        <JobListCard
                          key={jobcard?.id}
                          jobcard={jobcard}
                          delivered={true}
                        />
                      </Col>
                    ))
                ) : (
                  <Empty />
                )}
              </Row>
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default JobCardList;
