import "./index.scss";

import { useHistory } from "react-router";

import AppBar from "../../../Common/UI/AppBar";
import routes from "../../../Common/routes";
import EmployeeForm from "../Form";

const EmployeeCreate = () => {
  const history = useHistory();

  return (
    <div className="employeeCreate">
      <AppBar onBack={() => history.push(routes.LIST_EMPLOYEE("123"))}>
        <div className="autoAppbar-title">
          <h3>Create</h3>
        </div>
      </AppBar>
      <div className="employeeCreateContent">
        {/* <div className="employeeCreateAvatarContainer">
          <Avatar size={100} />
        </div> */}
        <EmployeeForm />
      </div>
    </div>
  );
};

export default EmployeeCreate;
