import "./index.scss";

import { Button, Form, Input, InputNumber } from "antd";

const ProfileForm = () => {
  return (
    <div className="employeeForm">
      <br />
      <br />
      <Form layout="vertical" requiredMark={false}>
        {/* Item Name */}
        <Form.Item
          name="name"
          label="Store Name"
          rules={[{ required: true, message: "Store name is required" }]}
        >
          <Input placeholder="Enter the name" />
        </Form.Item>

        {/* Email */}
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { type: "email", required: true, message: "Email is required" },
          ]}
        >
          <Input placeholder="Enter the email" />
        </Form.Item>

        {/* Address */}
        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Address is required",
            },
          ]}
        >
          <Input.TextArea placeholder="Enter the address" />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Phone number is required",
            },
          ]}
        >
          <InputNumber placeholder="Enter the phone number" />
        </Form.Item>

        <br />
        <Form.Item className="employeeForm-submit">
          <Button type="primary" style={{ width: "100%" }} htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfileForm;
