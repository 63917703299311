import { message } from "antd";
import { logError } from "../../../common/utils";
import {
  getIncentiveBonus,
  getServiceCategory,
  saveIncentiveBonus,
  setServicePrice,
} from "../../../infrastructure/services";

const actions = {
  setServiceCategory:
    (serviceCategory) =>
    ({ setState }) => {
      setState({ serviceCategory });
    },

  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  getServiceCategory:
    () =>
    async ({ dispatch }) => {
      try {
        const { StatusCode, data } = await getServiceCategory();
        if (StatusCode === 6000 && data) {
          console.log("serivcesss", data);
          dispatch(actions.setServiceCategory(data));
        } else {
          throw new Error("Could not fetch service categories");
        }
      } catch (error) {
        logError(error, " @ getServiceCategory()");
        message.error(error.toString());
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  setCurrentCategory:
    (currentCategory) =>
    ({ setState }) => {
      setState({ currentCategory });
    },
  setPriceService:
    (value, label, id) =>
    async ({ setState }) => {
      try {
        const formData = new FormData();
        formData.append(label, value);
        formData.append("as_service", id);
        const data = setServicePrice(formData);
        console.log(data);
      } catch {}
    },

  getCategory:
    (id, setLoading) =>
    async ({ dispatch }) => {
      try {
        const { StatusCode, data } = await getServiceCategory();
        if (StatusCode === 6000 && data) {
          dispatch(
            actions.setCurrentCategory(
              data.filter((category) => category?.id === id)?.[0]
            )
          );
        } else {
          throw new Error("Could not fetch service details.");
        }
      } catch (error) {
        logError(error, " @ getCategory()");
        message.error(error.toString());
      } finally {
        setLoading(false);
      }
    },
  getIncentiveBonus:
    (id) =>
    async ({ setState }) => {
      try {
        const { StatusCode, data } = await getIncentiveBonus(id);
        if (StatusCode === 6000 && data) {
          setState({ incentive: data?.incentive, bonus: data?.bonus });
        } else {
          throw new Error("Could not fetch service details.");
        }
      } catch (error) {
        logError(error, " @ getCategory()");
        message.error(error.toString());
      }
    },
  saveIncentiveBonus: (id, values, setPostLoading) => async () => {
    try {
      setPostLoading(true);
      const { StatusCode, data } = await saveIncentiveBonus(id, values);
      if (StatusCode === 6000 && data) {
        message.success("Service value updated successfully!");
      } else {
        throw new Error("Could not save service details.");
      }
    } catch (error) {
      logError(error, " @ getCategory()");
      message.error(error.toString());
    } finally {
      setPostLoading(false);
    }
  },
};

export default actions;
