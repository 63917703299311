import "./index.scss";

import { Button, Form, Input, Select, DatePicker, InputNumber } from "antd";
import { useInventoryStore } from "../../../Stores/Inventory";
import Loader from "../../../Common/UI/Loader";
import AppBar from "../../../Common/UI/AppBar";
import { useHistory } from "react-router-dom";
import routes from "../../../Common/routes";
import { useEffect } from "react";

const InventoryForm = () => {
  const [
    { loadingVarients, isAddingInventory, varientList },
    { handleAddInventory, handleGetVarientList },
  ] = useInventoryStore();

  const [form] = Form.useForm();

  useEffect(() => {
    varientList === null && handleGetVarientList();
  }, [handleGetVarientList, varientList]);

  const history = useHistory();

  return (
    <div className="inventoryCreate">
      <AppBar onBack={() => history.push(routes.LIST_INVENTORY)}>
        <div className="autoAppbar-title">
          <h3>Create</h3>
        </div>
      </AppBar>
      <br /> <br />
      <div id="add_stock" className="inventoryForm">
        <Form
          requiredMark={false}
          className="inventoryAddFormWrapper"
          form={form}
          onFinish={handleAddInventory || form.resetFields()}
        >
          {loadingVarients ? (
            <Loader size="small" tip="Loading inventory data..." />
          ) : (
            <>
              <Form.Item
                label="Category Name"
                name="category"
                rules={[{ required: true, message: "Category name is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "Price is required" }]}
              >
                <InputNumber min={1} />
              </Form.Item>
              <Form.Item
                label="RCP"
                name="rcp"
                rules={[{ required: true, message: "Rcp is required" }]}
              >
                <InputNumber min={1} />
              </Form.Item>
              <br />
              <Form.Item className="inventoryAddForm-submit">
                <Button
                  loading={isAddingInventory}
                  htmlType="submit"
                  type="primary"
                >
                  Add to Inventory
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default InventoryForm;
