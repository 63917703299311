import React, { useEffect } from "react";
import { Browser } from "@capacitor/browser";
import AppBar from "../../Common/UI/AppBar";
import routes from "../../Common/routes";
import { useHistory } from "react-router-dom";
import { FeatherIcon } from "../../Common/Assets";

const Index = () => {
  const history = useHistory();

  useEffect(() => {
    Browser.open({ url: "https://sajhedaari.com/" });
  }, []);

  return (
    <div>
      <AppBar onBack={() => history.push(routes.HOME)}>
        <div className="autoAppbar-title">
          <h3>Insurance</h3>
        </div>
      </AppBar>
    </div>
  );
};

export default Index;
