import { BrowserRouter, Route, Switch } from "react-router-dom";

import routes from "../Common/routes";

// START: Components
import Login from "../Screens/Login";
import Home from "../Screens/Home";
import InventoryList from "../Screens/Inventory/List";
import InventoryView from "../Screens/Inventory/View";
import InventoryForm from "../Screens/Inventory/Form";
import EmployeeDash from "../Screens/Employee/Dash";
import EmployeeList from "../Screens/Employee/List";
import EmployeeExpanded from "../Screens/Employee/Expanded";
import EmployeeEdit from "../Screens/Employee/Edit";
import EmployeeCreate from "../Screens/Employee/Create";
import JobCardView from "../Screens/JobCard/View";
import JobCardList from "../Screens/JobCard/List";
import JobCardHistory from "../Screens/JobCard/History";
import PrivateRouter from "./PrivateRouter";
import AuthReset from "../Screens/AuthReset";
import BayList from "../Screens/Bay/List";
import BayView from "../Screens/Bay/View";
import ProfileView from "../Screens/Profile/View";
import ProfileCreate from "../Screens/Profile/Create";
import ProfileEdit from "../Screens/Profile/Edit";
import ListReports from "../Screens/Reports/Dash";
import Insurance from "../Screens/Insurance/Index"
import ServicesList from "../Screens/Services/List";
import ServiceExpanded from "../Screens/Services/Expanded";
import CreateService from "../Screens/Services/Create";
import ViewReport from "../Screens/Reports/View";
import Attendance from "../Screens/Employee/Attendance";
import MapAttendance from "../Screens/Employee/MapAttendance";
import Manual from "../Screens/JobCard/ManualCard";
import SetService from "../Screens/JobCard/ManualCard/ServiceSelect";
import DailyReport from "../Screens/Home/DailyReport";
import ReportInvoice from "../Screens/JobCard/View/Common/ReportInvoice/";
import StoreReport from "../Screens/JobCard/View/Common/StoreReport/";
import RemarkReport from "../Screens/JobCard/View/Common/RemarkReport/";
import PullToRefresh from "react-simple-pull-to-refresh";
import ReportList from "../Screens/JobCard/View/Common/ReportList/";
// END: Components

const Router = () => {
  return (
    <PullToRefresh onRefresh={() => window.location.reload()}>
      <BrowserRouter>
        <Switch>
          <Route exact path={routes.LOGIN} component={Login} />
          <Route exact path={routes.AUTH_RESET} component={AuthReset} />
          <Route exact path={routes.LIST_JOB_CARD} component={JobCardList} />
          <Route
            exact
            path={routes.HISTORY_JOB_CARD}
            component={JobCardHistory}
          />
          <Route
            exact
            path={routes.VIEW_JOB_CARD(":jobId")}
            component={JobCardView}
          />
          <Route
            exact
            path={routes.VIEW_JOB_CARD_REPORT(":jobId")}
            component={ReportInvoice}
          />
          <Route
            exact
            path={routes.VIEW_SINGLE_REPORT(":jobid")}
            component={StoreReport}
          />
          <Route
            exact
            path={routes.VIEW_SINGLE_REPORT_REMARK(":jobid")}
            component={RemarkReport}
          />
          <Route
            exact
            path={routes.VIEW_DETAIL_REPORT(":jobId")}
            component={ReportList}
          />

          <PrivateRouter>
            <Route exact path={routes.HOME} component={Home} />
            <Route exact path={routes.EMPLOYEE} component={EmployeeDash} />
            <Route
              exact
              path={routes.LIST_EMPLOYEE(":categoryId")}
              component={EmployeeList}
            />

            <Route
              exact
              path={routes.EXPANDED_EMPLOYEE(":employeeId")}
              component={EmployeeExpanded}
            />
            <Route
              exact
              path={routes.EDIT_EMPLOYEE(":employeeId")}
              component={EmployeeEdit}
            />
            <Route
              exact
              path={routes.CREATE_EMPLOYEE}
              component={EmployeeCreate}
            />
            <Route
              exact
              path={routes.CREATE_INVENTORY}
              component={InventoryForm}
            />
            <Route
              exact
              path={routes.LIST_INVENTORY}
              component={InventoryList}
            />
            <Route
              exact
              path={routes.VIEW_INVENTORY(":inventoryId")}
              component={InventoryView}
            />

            <Route
              exact
              path={routes.CREATE_PROFILE}
              component={ProfileCreate}
            />
              <Route
              exact
              path={routes.INSURANCE}
              component={Insurance}
            />
            <Route exact path={routes.VIEW_PROFILE} component={ProfileView} />
            <Route exact path={routes.EDIT_PROFILE} component={ProfileEdit} />
            <Route exact path={routes.LIST_BAY} component={BayList} />
            <Route exact path={routes.VIEW_BAY(":bayId")} component={BayView} />
            <Route exact path={routes.VIEW_MAP} component={MapAttendance} />
            <Route
              exact
              path={routes.VIEW_ATTENDANCE(":employee_id")}
              component={Attendance}
            />
            <Route exact path={routes.SERVICES} component={ServicesList} />
            <Route
              exact
              path={routes.EXPANDED_SERVICE(":serviceId")}
              component={ServiceExpanded}
            />
            <Route
              exact
              path={routes.CREATE_SERVICE}
              component={CreateService}
            />
            <Route exact path={routes.LIST_REPORTS} component={ListReports} />
            <Route
              exact
              path={routes.VIEW_REPORT(":reportType")}
              component={ViewReport}
            />
            <Route exact path={routes.MANUAL_CARD} component={Manual} />
            <Route exact path={routes.MANUAL_FINAL} component={SetService} />
            <Route exact path={routes.DAILY_REPORT} component={DailyReport} />
          </PrivateRouter>
        </Switch>
      </BrowserRouter>
    </PullToRefresh>
  );
};

export default Router;
