import React, { useEffect } from "react";
import "./stylesheet.scss";
import JobAppBar from "../../../Common/JobAppBar";
import routes from "../../../../../Common/routes";
import { useHistory } from "react-router-dom";
import { useJobcardStore } from "../../../../../Stores/Jobcard";
import Remarks from "../../../ManualCard/Remarks/Index";
import moment from "moment";
import logoKraft from "../../../../../Common/Assets/logoKraft.png";
import { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import index from "../../../../Home/RingProcess";

const Index = () => {
  const [{ UserCarData, jobCardData, render }, { viewSingleJob }] =
    useJobcardStore();
  const history = useHistory();
  useEffect(() => {
    viewSingleJob(UserCarData?.id);
  }, [UserCarData, render]);

  const inputRef = useRef(null);
  const printDocument = () => {
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 50, 0);
      pdf.save("download.pdf");
    });
  };

  console.log(UserCarData, jobCardData, "user car data now");

  return (
    <div className="ReportInvoice" style={{ paddingTop: "0!important" }}>
      <JobAppBar
        onBack={() => history.push(routes.VIEW_JOB_CARD(jobCardData?.id))}
        title="Reports"
      />
      <div
        id="divToPrint"
        ref={inputRef}
        class="container-fluid invoice-container"
        style={{ paddingTop: "12px" }}
      >
        <header>
          <div class="row align-items-center">
            <div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
              {" "}
              <img
                style={{ width: "110px" }}
                id="logo"
                src={logoKraft}
                title="Koice"
                alt="Koice"
              />{" "}
            </div>
            <div class="col-sm-5 text-center text-sm-end">
              <h4 class="mb-0">Invoice</h4>
              <p class="mb-0">Invoice Number - {jobCardData?.job_id}</p>
            </div>
          </div>
          {/* <hr> */}
        </header>

        <main>
          <div class="row">
            <div class="col-sm-6 mb-3">
              {" "}
              <strong>Transaction ID:</strong>{" "}
              <span>{jobCardData?.job_id}</span>{" "}
            </div>
            <div class="col-sm-6 mb-3 text-sm-end">
              {" "}
              <strong>Booking Date:</strong>{" "}
              <span>
                {moment(jobCardData?.date_added).format("DD/MM/YYYY")}
              </span>{" "}
            </div>
          </div>
          {/* <hr class="mt-0"> */}
          <span className="report_media_wrap">
            <div class="row">
              <div class="col-sm-4">
                {" "}
                <strong>Customer:</strong>
                <p>{UserCarData?.customer_details?.name}</p>
                <strong>Vehicle No:</strong>
                <p>{UserCarData?.rc_number}</p> <strong>Floor Manager:</strong>
                <p>{jobCardData?.floor_manager}</p>
                <div class="col-sm-4">
                  {" "}
                  <strong>Phone:</strong>
                  <p>{jobCardData?.customer_details?.phone}</p>
                </div>
                <div class="col-sm-4">
                  {" "}
                  <strong>Email:</strong>
                  <p>{jobCardData?.customer_details?.email}</p>
                </div>
                <div class="col-sm-4">
                  {" "}
                  <strong>Address:</strong>
                  <p>{jobCardData?.customer_details?.address}</p>
                </div>
                <div class="col-sm-4">
                  {" "}
                  <strong>Brand:</strong>
                  <p>{jobCardData?.customer_vehicle_data?.brand}</p>
                </div>
                <div class="col-sm-4">
                  {" "}
                  <strong>Model:</strong>
                  <p>{jobCardData?.customer_vehicle_data?.model}</p>
                </div>
                <div class="col-sm-4">
                  {" "}
                  <strong>Year:</strong>
                  <p>{jobCardData?.customer_vehicle_data?.year}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "24px",
              }}
            >
              <Remarks
                id={jobCardData?.customer_details?.id}
                imageVal={false}
              />
            </div>
          </span>

          <div class="card" style={{ marginTop: "30px" }}>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="card-header">
                    <tr>
                      <td class="col-2">
                        <strong>Position</strong>
                      </td>
                      {/* <td class="col-4 text-end">
                        <strong>Rate</strong>
                      </td> */}
                      <td class="col-2 text-end">
                        <strong>Remarks</strong>
                      </td>
                      <td class="col-4 text-end">
                        <strong>Additional remarks</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {UserCarData?.vehicle_views?.map((res) => (
                      <tr>
                        <td class="col-2">{res?.position}</td>
                        <td class="col-2 text-end">{res?.remarks}</td>
                        <td class="col-4"></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table class="table mb-0">
                  <thead class="card-header">
                    <tr>
                      <td class="col-4">
                        <strong>Service</strong>
                      </td>
                      <td class="col-2 ">
                        <strong>Employee</strong>
                      </td>
                      <td class="col-2 ">
                        <strong>Bay</strong>
                      </td>

                      <td class="col-2 ">
                        <strong>Amount</strong>
                      </td>
                      <td class="col-2 text-end">
                        <strong>GST</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {jobCardData?.jobcardservices?.map((res) => (
                      <tr>
                        <td class="col-4">{res?.service_name}</td>
                        <td class="col-2">{res?.employee_name}</td>
                        <td class="col-2">{res?.bay_name}</td>
                        <td class="col-2 ">{res?.price}</td>
                        <td class="col-2 text-end">
                          {res?.gst}%<br />
                          ₹{res?.price * (res?.gst / 100)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot class="card-footer">
                    <tr>
                      <td class="text-end border-bottom-0">
                        <strong>Total:</strong>
                      </td>
                      <td colspan="4" class="text-end border-bottom-0">
                        {jobCardData?.total_price}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div>
            <h5 style={{ color: "black", paddingTop: "12px" }}>Remarks</h5>
            {jobCardData?.comments?.map((res, index) => (
              <p>
                {index + 1}.{res?.remarks}
              </p>
            ))}
          </div>
          {/* <h4 class="text-3 mt-4">Important Note:</h4>
          <ul class="text-1">
            <li>
              Tickets & food once ordered cannot be exchanges, cancelled or
              refunded.
            </li>
            <li>
              Children aged 3 years an above will require a separate ticket.
            </li>
          </ul> */}
        </main>
        <footer class="text-center">
          {/* <p><strong>Koice Inc.</strong><br>
            4th Floor, Plot No.22, Above Public Park, 145 Murphy Canyon Rd,<br>
            Suite 100-18, San Diego CA 2028. </p> */}
          <p class="text-1">
            <strong>NOTE :</strong> This is computer generated receipt and does
            not require physical signature.
          </p>
          <div class="btn-group btn-group-sm d-print-none">
            {" "}
            <a
              href="javascript:window.print()"
              class="btn btn-light border text-black-50 shadow-none"
            >
              <i class="fa fa-print" onClick={() => window.print()}></i> Print
            </a>{" "}
          </div>
        </footer>
        <footer
          class="text-center"
          style={{
            borderTop: "1px solid black",
            marginTop: "12px",
            paddingTop: "12px",
          }}
        >
          {/* <p><strong>Koice Inc.</strong><br>
            4th Floor, Plot No.22, Above Public Park, 145 Murphy Canyon Rd,<br>
            Suite 100-18, San Diego CA 2028. </p> */}
          <p class="text-1">
            <strong>Gate Pass </strong>
          </p>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div class="col-sm-4">
              {" "}
              <strong>Party:</strong>&nbsp;{UserCarData?.customer_details?.name}
              {/* <p>{jobCardData?.customer_details?.phone}</p> */}
            </div>
            <div class="col-sm-4">
              {" "}
              <strong>Vehicle No:</strong>&nbsp;{UserCarData?.rc_number}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Index;
