import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";
import config from "../../../common/config";

const DashboardStore = createStore({
  name: `automoto_store_${config.ENV}_employee`,
  initialState,
  actions,
});

export const useDashboardStore = createHook(DashboardStore, {
  selector: (state) => state,
});
