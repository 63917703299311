import { message } from "antd";
import { logError, msgError } from "../../../common/utils";
import { getBayList } from "../../../infrastructure/bay";
import { getEmployeeList } from "../../../infrastructure/employee";
import { listInventory, sellProduct } from "../../../infrastructure/inventory";
import {
  acceptJobcard,
  addProductToJobcard,
  assignJobcard,
  declineJobcard,
  deliverJobcard,
  listJobcards,
  viewJobcard,
  userVerify,
  userVehicles,
  createUser,
  userVehicleAdd,
  userBrandAdd,
  addTocart,
  deleteFromCart,
  placeOrderStart,
  createManualJob,
  listReport,
  setDefaultCar,
  getUserCarData,
  postCarImage,
  confirmData,
  viewJobCard,
  addSpareToCart,
  deleteFromCartSpare,
  serviceStatus,
  setAdvance,
  setRemarkNew,
  deletRemark,
  updateRemarkNew,
  floorManagerAssign,
  deletRemarkImage,
} from "../../../infrastructure/jobcard";
import routes from "../../Common/routes";

const actions = {
  setRerender:
    (val) =>
    ({ setState }) => {
      setState({ render: !val });
    },
  setLoadingCardList:
    (loadingCardList) =>
    ({ setState }) => {
      setState({ loadingCardList });
    },
  setVisible:
    (visible) =>
    ({ setState }) => {
      setState({ visible });
    },
  setVisibleSpare:
    (visibleSpare) =>
    ({ setState }) => {
      setState({ visibleSpare });
    },
  setVisibleAdvance:
    (visibleAdvance) =>
    ({ setState }) => {
      setState({ visibleAdvance });
    },
  setLoader:
    (loader) =>
    ({ setState }) => {
      setState({ loader });
    },

  setJobcardList:
    (jobcardList) =>
    ({ setState }) => {
      setState({ jobcardList });
    },

  setLoadingJobcard:
    (loadingJobcard) =>
    ({ setState }) => {
      setState({ loadingJobcard });
    },
  setLoadingButton:
    (loadingButton) =>
    ({ setState }) => {
      setState({ loadingButton });
    },

  setJobcard:
    (jobcard) =>
    ({ setState }) => {
      setState({ jobcard });
    },

  setProductList:
    (productList) =>
    ({ setState }) => {
      setState({ productList });
    },

  setViewAssignModal:
    (viewAssignModal) =>
    ({ setState }) => {
      setState({ viewAssignModal });
    },

  setAddProductModal:
    (addProductModal) =>
    ({ setState }) => {
      setState({ addProductModal });
    },

  setLoadingAssignModal:
    (loadingAssignModal) =>
    ({ setState }) => {
      setState({ loadingAssignModal });
    },

  setLoadingReportModal:
    (loadingReportModal) =>
    ({ setState }) => {
      setState({ loadingReportModal });
    },

  setViewReportModal:
    (viewReportModal) =>
    ({ setState }) => {
      setState({ viewReportModal });
    },
  handleGetReportList:
    (reportList) =>
    ({ setState }) => {
      setState({ reportList });
    },

  setIsAssigning:
    (isAssigning) =>
    ({ setState }) => {
      setState({ isAssigning });
    },

  setIsAddingProduct:
    (isAddingProduct) =>
    ({ setState }) => {
      setState({ isAddingProduct });
    },

  setIsSelling:
    (isSelling) =>
    ({ setState }) => {
      setState({ isSelling });
    },

  setIsUpdatingStatus:
    (isUpdatingStatus) =>
    ({ setState }) => {
      setState({ isUpdatingStatus });
    },

  handleAddProductToService:
    (serviceId, serialNo, callback) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsAddingProduct(true));
      try {
        const res = await addProductToJobcard(serviceId, {
          serial_number: serialNo,
        });
        if (res?.StatusCode === 6000) {
          await sellProduct(serialNo);
          callback && callback();
          dispatch(actions.setAddProductModal(null));
          message.success("Product added !");
        }
      } catch (error) {
        logError(error, "handleAddProductToService()");
        msgError(error);
      } finally {
        dispatch(actions.setIsAddingProduct(false));
      }
    },

  confirmUserData:
    (data) =>
    async ({ setState, dispatch, getState }) => {
      try {
        const res = await confirmData(data);
        setState({ jobCardId: res?.data?.job });
        message.success(res.data.message);
        window.location.reload();
      } catch (er) {
        dispatch(actions.setRerender(getState().render));
      }
    },
  viewSingleJob:
    (data) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setLoader(true));
      try {
        const res = await viewJobCard(data);
        setState({ jobCardData: res.data });
        dispatch(actions.setLoader(false));
      } catch (er) {
        console.log(er.response, "error dataaa");
        dispatch(actions.setLoader(false));
      }
    },

  handleGetJobcard:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingJobcard(true));
      try {
        const response = await viewJobcard(id);
        if (response?.StatusCode === 6000) {
          dispatch(actions.setJobcard(response.data));
          dispatch(actions.handleGetProductList());
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetJobcard()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingJobcard(false));
      }
    },

  handleGetAssignModalData:
    (callback) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setLoadingAssignModal(true));
      try {
        const empResponse = await getEmployeeList();
        if (empResponse?.StatusCode === 6000) {
          setState({ assignEmployeeList: empResponse.data || [] });
        } else {
          message.warn(empResponse?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetAssignModalData()");
        message.error("Error in fetching employee list");
      }
      try {
        const bayResponse = await getBayList();
        if (bayResponse?.StatusCode === 6000) {
          setState({ assignBayList: bayResponse.data || [] });
        } else {
          message.warn(bayResponse?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetAssignModalData()");
        message.error("Error in fetching bay list");
      } finally {
        dispatch(actions.setLoadingAssignModal(false));
        callback && callback();
      }
    },

  handleSellJobcardProduct:
    (serialNo, setserialNo, id) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsSelling(true));
      try {
        const formData = new FormData();
        formData.append("serial_number", serialNo);
        await sellProduct(formData, id);
        setserialNo("");
      } catch (error) {
        logError(error);
        msgError(error);
      } finally {
        dispatch(actions.setIsSelling(false));
      }
    },

  handleAssignJobcard:
    (id, data, callback, jobId) =>
    async ({ dispatch }) => {
      dispatch(actions.setIsAssigning(true));
      const formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }
      try {
        const response = await assignJobcard(id, formData);
        if (response?.StatusCode === 6000) {
          message.success("Jobcard assigned !");
          callback && callback();
          dispatch(actions.setViewAssignModal(false));
          dispatch(actions.handleGetJobcard(jobId));
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleAssignJobcard()");
        msgError(error);
      } finally {
        dispatch(actions.setIsAssigning(false));
      }
    },

  handleGetProductList:
    () =>
    async ({ dispatch }) => {
      try {
        const response = await listInventory();
        dispatch(actions.setProductList(response.data));
      } catch (error) {
        logError(error, "handleGetInventoryList()");
        msgError(error);
      }
    },

  handleUpdateStatusJobcard:
    (newStatus, id) =>
    async ({ dispatch }) => {
      console.log("status", id);
      dispatch(actions.setIsUpdatingStatus(true));
      try {
        let response;
        if (newStatus === "accept") {
          response = await acceptJobcard(id);
        } else if (newStatus === "deliver") {
          response = await deliverJobcard(id);
          window.history.back();
        } else if (newStatus === "decline") {
          response = await declineJobcard(id);
        }
        if (response?.StatusCode === 6000) {
          message.success(`Jobcard ${newStatus}ed !`);
          dispatch(actions.handleGetJobcard(id));
        } else if (response?.data?.message) {
          message.warn(response?.data?.message);
        } else {
          message.error("Some error occured");
        }
      } catch (error) {
        logError(error);
        msgError(error);
      } finally {
        dispatch(actions.setIsUpdatingStatus(false));
      }
    },

  handleGetAllJobcards:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setLoadingCardList(true));
      try {
        const response = await listJobcards();
        console.log(response, "report");
        if (response?.StatusCode === 6000) {
          dispatch(actions.setJobcardList(response.data || []));
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetAllJobcards()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingCardList(false));
      }
    },

  handleGetReport:
    (id) =>
    async ({ dispatch }) => {
      try {
        const response = await listReport(id);
        if (response?.StatusCode === 6000) {
          dispatch(actions.handleGetReportList(response?.data));
        } else {
          message.warn(response?.data?.message);
        }
      } catch (error) {
        logError(error, "handleGetJobcard()");
        msgError(error);
      } finally {
        dispatch(actions.setLoadingReportModal(false));
      }
    },
  getUserCarData:
    (id) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setLoader(true));
      try {
        const res = await getUserCarData(id);
        setState({ UserCarData: res.data });
        dispatch(actions.setLoader(false));
      } catch (error) {
        logError(error, "handleGetJobcard()");
        msgError(error);
      } finally {
      }
    },
  postCarImage:
    (id, values, position, capturedPhoto) =>
    async ({ setState, dispatch }) => {
      console.log(capturedPhoto, "captured Photo", values);
      try {
        console.log(id, "valuesss");
        console.log(typeof capturedPhoto, "camera type");
        console.log("checkc", capturedPhoto);
        const formdata = new FormData();
        formdata.append("position", position);
        if (capturedPhoto) {
          formdata.append("photo", capturedPhoto);
        }
        // if (values?.photo) {
        //   formdata.append("photo", values?.photo?.file?.originFileObj);
        // }

        if (values?.remarks) {
          formdata.append("remarks", values?.remarks);
        }
        await postCarImage(id, formdata);
        dispatch(actions.setVisible(false));
      } catch (error) {
        logError(error, "handleGetJobcard()");
        msgError(error);
      } finally {
      }
    },

  verifyUser:
    (values) =>
    async ({ setState }) => {
      console.log(values, "values");
      const formData = new FormData();
      formData.append("country", "IN");
      formData.append("phone", values);
      // setTimeout(function () {
      //   your_func();
      // }, 5000);
      if (values.length === 10) {
        var verify = await userVerify(formData);
        if (verify.data.name === null) {
          setState({ status: false });
        } else {
          setState({ status: true, verfiedUser: verify?.data });
          var res = await userVehicles(verify?.data?.customer_id);
          console.log(res, "user vehicles");
          setState({ userVehicles: res.data });
        }
        console.log(verify, "verify value");
      }
    },
  createProfile:
    (values) =>
    async ({ setState }) => {
      const formData = new FormData();
      formData.append("country", "IN");
      formData.append("phone", values.number);
      formData.append("name", values.name);
      formData.append("email", values.email);
      try {
        var verify = await createUser(formData);
        console.log(verify, "new user");
        setState({
          status: true,
          verfiedUser: verify?.data,
        });
      } catch (er) {
        console.log(er.response);
        message.warning(er.response.data.data.email[0]);
      }
    },
  setDefaultCar:
    (customerID, values) =>
    async ({ setState }) => {
      console.log(customerID, values, "values");
      let params = {
        id: values,
      };
      await setDefaultCar(customerID, params);
    },
  getVehicleData:
    (values, user_id) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setLoadingButton(true));
      try {
        var vehicle = await userVehicleAdd(values.rc, user_id);
        console.log(vehicle);
        if (vehicle?.StatusCode === 6000) {
          setState({ userBrands: vehicle });
          dispatch(actions.setbrandModal(true));
          dispatch(actions.setLoadingButton(false));
        } else {
          message.error(vehicle?.message);
          dispatch(actions.setLoadingButton(false));
        }
      } catch (er) {
        dispatch(actions.setLoadingButton(false));
        console.log(er.response, "errrorr valuee");
        message.warning(er?.response?.data?.message);
      }
    },
  setbrandModal:
    (params) =>
    async ({ setState }) => {
      console.log(params, "modal");
      setState({ brandModal: params });
    },
  addUserBrand:
    (car_id, user_id, rc) =>
    async ({ setState }) => {
      const data = await userBrandAdd(car_id, user_id, rc);
      console.log(data, "car added");
      message.success("Car added");
    },
  addServiceCart:
    (jobcard, service) =>
    async ({ getState, dispatch }) => {
      console.log(jobcard, service, "checking daata nwo");
      const formData = new FormData();
      formData.append("service", service);
      formData.append("price", 0);
      formData.append("gst", 0);
      await addTocart(jobcard, formData);
      dispatch(actions.setRerender(getState().render));
    },
  startPayment:
    (user) =>
    async ({ setState }) => {
      var cart = await placeOrderStart(user);
      console.log(cart.data.total_amount, "place data");
      await createManualJob(user);
      message.success("Job Created");
      window.location.replace(routes.LIST_JOB_CARD);
    },
  deleteCart:
    (item, user) =>
    async ({ dispatch, getState }) => {
      await deleteFromCart(item);
      dispatch(actions.setRerender(getState().render));
    },
  deleteCartSpare:
    (item, user) =>
    async ({ dispatch, getState }) => {
      await deleteFromCartSpare(item);
      dispatch(actions.setRerender(getState().render));
      dispatch(actions.setVisibleSpare(false));
      message.success("Successfully deleted Spare");
    },
  editSingleService:
    (value, service, jobcardId, key) =>
    async ({ dispatch, getState }) => {
      const formData = new FormData();
      formData.append("service", service);
      formData.append(key, value);
      await addTocart(jobcardId, formData);
      // dispatch(actions.setRerender(getState().render));
    },
  editSingleSparePrice:
    (spareId, serviceId, value, key) =>
    async ({ dispatch, getState }) => {
      console.log(spareId, value, serviceId, "checking cshirr");
      const formData = new FormData();
      formData.append("spare", spareId);
      formData.append(key, value);
      await addSpareToCart(serviceId, formData);
      dispatch(actions.setRerender(getState().render));
    },
  addSpares:
    (id, price, jobcardId) =>
    async ({ dispatch, getState }) => {
      const formData = new FormData();
      formData.append("spare", id);
      formData.append("price", price);
      formData.append("gst", 0);
      formData.append("qty", 1);
      await addSpareToCart(jobcardId, formData);
      dispatch(actions.setRerender(getState().render));
      dispatch(actions.setVisibleSpare(false));
      message.success("Successfully added Spare");
    },
  setServiceStatus:
    (id, val) =>
    async ({ dispatch, getState }) => {
      await serviceStatus(id, val);
      dispatch(actions.setRerender(!getState().render));
    },
  addAdvance: (id, val) => async () => {
    const formData = new FormData();
    formData.append("advance", val);
    await setAdvance(id, formData);
  },
  addNewRemark:
    (val, id) =>
    async ({ dispatch, getState }) => {
      const formData = new FormData();
      formData.append("remarks", val);
      await setRemarkNew(id, formData);
      dispatch(actions.setRerender(getState().render));
    },
  editNewRemark:
    (val, id) =>
    async ({ dispatch, getState }) => {
      const formData = new FormData();
      formData.append("remarks", val);
      await updateRemarkNew(id, formData);
      dispatch(actions.setRerender(getState().render));
    },
  deleteRemarksSingle:
    (id) =>
    async ({ dispatch, getState }) => {
      await deletRemark(id);
      dispatch(actions.setRerender(getState().render));
      message.success("Removed remark");
    },
  deleteImageRemark:
    (viewId, id) =>
    async ({ dispatch, getState }) => {
      const formData = new FormData();
      formData.append("image_id", id);
      await deletRemarkImage(viewId, formData);
      message.success("Deleted succesfully");
      dispatch(actions.setVisible(false));
      // dispatch(actions.setRerender(getState().render));
      window.location.reload();
    },
  setFloorManage:
    (val, id) =>
    async ({ dispatch, getState }) => {
      const formData = new FormData();
      console.log(val, "valuesssss");
      formData.append("floor_manager", val);
      await floorManagerAssign(id, formData);
      dispatch(actions.setRerender(getState().render));
    },
};

export default actions;
