import { InlineInputEdit } from "react-inline-input-edit";

const JobInvoice = ({ jobcard, editService, id, rerender, editProduct }) => {
  const _handleFocus = (text) => {
    console.log("Focused with text: " + text);
  };

  const _handleFocusOut = (text) => {
    console.log("Left editor with text: " + text);
  };
  if (jobcard?.services?.length < 1) return <></>;
  return (
    <div className="jobCardView-invoices">
      <br />
      <h3 className="jobCardView-title">Invoice Details</h3>
      <p className="jobCardView-invoices-hd">Order#</p>
      <h3 className="jobCardView-invoices-id">{jobcard?.reg_no}</h3>
      <br />
      {jobcard?.jobcardservices?.map((service, index) => (
        <div key={index} className="jobCardView-invoice">
          <p>{index + 1}.</p>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <p>
              {service?.service_name}
              <br />
              {/* <span>{`${jobcard?.reg_no}_${index}`}</span> */}
            </p>
            {service?.spares !== null && (
              <span>
                <p style={{ fontSize: "12px" }}>Products under service:</p>
                <h5
                  style={{
                    paddingLeft: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {service?.spares?.map((data, ind) => (
                    <h6 style={{ fontSize: "12px", color: "white" }}>
                      {ind + 1}.&nbsp;{data.spare_name}
                      <span style={{ paddingLeft: "24px" }}>
                        <InlineInputEdit
                          text={String(data?.price)}
                          inputWidth="100px"
                          inputHeight="25px"
                          inputMaxLength={50}
                          onFocus={_handleFocus}
                          onFocusOut={async (text) => {
                            await editProduct(
                              data?.spare,
                              service?.id,
                              text,
                              "price"
                            );
                            rerender(id);
                          }}
                        />
                      </span>
                      <span></span>
                    </h6>
                  ))}
                </h5>
              </span>
            )}
          </span>

          {/* <p>{service?.price}</p> */}
          <p>
            {" "}
            <InlineInputEdit
              text={String(service?.price)}
              inputWidth="100px"
              inputHeight="25px"
              inputMaxLength={50}
              onFocus={_handleFocus}
              onFocusOut={async (text) => {
                await editService(text, service?.service, jobcard?.id, "price");
                rerender(id);
              }}
            />
          </p>
        </div>
      ))}
      <div className="jobCardView-invoice-divider"></div>
      <div className="jobCardView-invoice">
        <p></p>
        <p>TOTAL</p>
        <p>{jobcard?.total_price}</p>
      </div>
    </div>
  );
};

export default JobInvoice;
